import { Button, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ count, limit, setPage, page }) => {
  const handlePage = (e) => {
    console.log(e, "selected");
    setPage(e.selected + 1);
  };
  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePage}
        pageRangeDisplayed={5}
        pageCount={Math.ceil(count / 10)}
        previousLabel="< prev"
        renderOnZeroPageCount={null}
        previousClassName={"item previous"}
        pageClassName={"item pagination-page "}
        containerClassName={"pagination"}
        disabledClassName={"disabled-page"}
        activeClassName={"item active-pagination "}
        forcePage={page - 1}
      />
    </>
    // <Flex gap="5">
    //   <Button
    //     onClick={() => setPage((old) => Math.max(old - 1, 0))}
    //     disabled={page === 1}>
    //     {"<"}
    //   </Button>
    //   {Array.from({ length: Math.ceil(count / limit) }, (item, index) => (
    //     <Button
    //       disabled={index + 1 === page}
    //       onClick={() => {
    //         setPage(index + 1);
    //       }}
    //       key={index}
    //       variant={page === index + 1 ? "primary" : "primaryOutline"}>
    //       {index + 1}
    //     </Button>
    //   ))}
    //   <Button
    //     onClick={() => {
    //       setPage((old) => old + 1);
    //     }}
    //     disabled={Math.ceil(count / limit) === page}>
    //     {">"}
    //   </Button>
    // </Flex>
  );
};

export default Pagination;
