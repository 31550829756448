import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { getPorts } from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import { useLocation, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addVerticle } from "../../services/apis/touAgencyApi";
import { states } from "../../services/constants";
import DropZoneImage from "../../components/mics/Inputs/DropZone";
//d7d7d770
const JornayaLeads = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1
  );
  const [limit, setLimit] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("l")) : 10
  );
  const [search, setSearch] = useState(
    searchParams.get("input") ? searchParams.get("input") : ""
  );

  const [images, setImages] = useState([]);

  const {
    register,
    handleSubmit,

    reset,
    formState: { errors },
  } = useForm();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["ports", page, limit, search],
    queryFn: () => getPorts(page, limit, search),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();
  const { mutate, isLoading: addLoading } = useMutation(addVerticle, {
    onError: (error) => {
      toast.error(error.response.data.msg);
    },
    onSuccess: () => {
      toast.success("Port added successfully");
      queryClient.invalidateQueries("ports");
      reset();
    },
  });

  console.log(data, "data");

  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }
  const handelForm = (e) => {
    e.preventDefault();
    setSearch(e?.target?.value);
    setSearchParams({ input: e?.target?.value, p: page, l: limit });
    setPage(1);
  };

  const onSubmit = (value) => {
    console.log(value);
    if (images && images.length) {
      let formData = new FormData();
      formData.append("file", images[0]);
      formData.append("state", value.state);
      mutate(formData);
    }
  };
  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Jornaya leads
        </Text>
      </Flex>
      <Flex justify={"space-between"} flexWrap="wrap" gap={10}>
        <Box
          bg={"white"}
          p={5}
          mt={"73px"}
          borderRadius="xl"
          boxShadow={"xl"}
          height={"fit-content"}>
          <Box>
            <Text
              fontSize={["1xl", "xl", "xl", "2xl"]}
              as="h1"
              fontWeight="bold">
              Add Leads
            </Text>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex gap={8} minW={"300px"} direction={"column"}>
              <FormControl mt={4}>
                <FormLabel>State</FormLabel>
                <Select
                  {...register("state", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select state">
                  {!isLoading &&
                    states.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </Select>
                <FormHelperText color="red">
                  {errors.state && errors.state.message}
                </FormHelperText>
              </FormControl>
              <Box>
                <FormLabel>Upload Csv</FormLabel>
                <DropZoneImage
                  maxFiles={1}
                  setImages={setImages}
                  images={images}
                />
              </Box>

              <Flex justify={"end"}>
                <Button type="submit" colorScheme="green" variant="solid">
                  {addLoading ? <Spinner /> : "Send"}
                </Button>
              </Flex>
            </Flex>
          </form>
        </Box>
        <Box w={["100%", "100%", "100%", "1000px"]}>
          <Flex justify={"end"} p={4}>
            <Flex gap={2} align="center">
              <Select onChange={handelForm} placeholder="Search By State">
                {!isLoading &&
                  states.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </Select>
            </Flex>
          </Flex>
          <TableContainer bg={color} borderRadius="lg">
            <Table>
              <Thead bg={colorz}>
                <Tr>
                  <Th p="10px" textAlign="center">
                    State
                  </Th>
                  <Th p="10px" textAlign="center">
                    Port
                  </Th>
                  <Th p="10px" textAlign="center">
                    Status
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isLoading &&
                  data?.data &&
                  data?.data?.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td textAlign="center" p="10px">
                          {item.state ? item.state : "-"}
                        </Td>
                        <Td textAlign="center" p="10px">
                          {item.port ? item.port : "-"}
                        </Td>

                        <Td textAlign="center" p="10px">
                          {item.status ? item.status : "-"}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            {/* <Flex align="center" gap="6">
              {!isLoading && (
                <Select
                  value={limit}
                  onChange={(e) => setLimit(parseInt(e.target.value))}>
                  <option value={4}>4</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </Select>
              )}
              {data?.data && (
                <Text>
                  Showing {limit} out 0f {data?.count}
                </Text>
              )}
            </Flex> */}
            {data?.data ? (
              <Pagination
                count={data?.count}
                limit={limit}
                page={page}
                setPage={setPage}
              />
            ) : (
              <PaginationLoader />
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default JornayaLeads;
