import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getAutoLeads } from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
//d7d7d770
const AutoLeads = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  const [selected, setSelected] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1,
  );
  const [limit, setLimit] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("l")) : 10,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["auto_leads", page, limit],
    queryFn: () => getAutoLeads(page, limit),
    keepPreviousData: true,
  });

  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }

  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Auto Insurance leads
        </Text>
      </Flex>

      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              {/* <Th p="10px" textAlign="center">
                  <Tooltip hasArrow label="Support">
                    S
                  </Tooltip>
                </Th> */}
              <Th p="10px" textAlign="center">
                id
              </Th>
              <Th p="10px" textAlign="center">
                status
              </Th>
              <Th p="10px" textAlign="center">
                journayaId
              </Th>
              <Th p="10px" textAlign="center">
                IP
              </Th>
              <Th p="10px" textAlign="center">
                Zipcode
              </Th>
              <Th p="10px" textAlign="center">
                Name
              </Th>
              <Th p="10px" textAlign="center">
                Email
              </Th>
              <Th p="10px" textAlign="center">
                Phone
              </Th>
              <Th p="10px" textAlign="center">
                State
              </Th>
              <Th p="10px" textAlign="center">
                Current Insurance Provider
              </Th>
              <Th p="10px" textAlign="center">
                DUI / Accident Last 6 Months?
              </Th>
              <Th p="10px" textAlign="center">
                DUI / Accident Last 6 Months?
              </Th>
              <Th p="10px" textAlign="center">
                address
              </Th>
              <Th p="10px" textAlign="center">
                Response
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data.data &&
              data.data.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td textAlign="" p="10px">
                      {item?.id}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.status}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.journayaId}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.ip}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.zip}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.firstname} {item?.lastname}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.email}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.phoneNumber}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.state}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.currentInsuranceProvider}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.past}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.dui}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.address}
                    </Td>
                    <Td textAlign="" p="10px">
                      <IconButton
                        onClick={() => {
                          setSelected(item);
                          onOpen();
                        }}
                      >
                        <AiFillEye />
                      </IconButton>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex p={[4, 4, 4, 4]} justify="space-between">
        {data ? (
          <Pagination
            count={data.count}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <PaginationLoader />
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lead Response#{selected?.id}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <pre
              style={{
                backgroundColor: "#f4f4f4",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "4px",
              }}
            >
              {JSON.stringify(selected?.response, null, 2)}
            </pre>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AutoLeads;
