import {
  Box,
  CircularProgress,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Button, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { IoIosAdd } from "react-icons/io";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  addServiceData,
  getProjects,
  getProjectData,
  getService,
  deleteServiceData,
  bulkDeleteServiceData,
} from "../../services/apis/touAgencyApi";
import { GrFormView } from "react-icons/gr";
import ErrorImage from "../../asserts/error.png";
import { toast } from "react-toastify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Tooltip } from "chart.js";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { states } from "../../services/constants";
import Pagination from "../../components/Pagination/Pagination";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import moment from "moment";
import DropZoneImage from "../../components/mics/Inputs/DropZone";
import { AiFillDelete } from "react-icons/ai";

const ProjectDetails = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  const [modal, setModal] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1,
  );
  const [limit, setLimit] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("l")) : 10,
  );
  const [search, setSearch] = useState(
    searchParams.get("input") ? searchParams.get("input") : "",
  );
  const [service, setService] = useState(null);
  const [images, setImages] = useState([]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate: mutateDeleteServiceData, isLoading: loadingDelete } =
    useMutation(deleteServiceData, {
      onSuccess: () => {
        toast.success("service data deleted sucessfully");
        queryClient.invalidateQueries("projectData");
      },
      onError: () => {
        toast.error("Coun't delete service data");
      },
    });

  const { mutate: mutateBulkDelete, isLoading: loadingBulk } = useMutation(
    bulkDeleteServiceData,
    {
      onSuccess: () => {
        toast.success("service data deleted sucessfully");
        queryClient.invalidateQueries("projectData");
        setModal(false);
      },
      onError: () => {
        toast.error("Coun't delete service data");
      },
    },
  );

  const {
    register: zipCodeRegister,
    handleSubmit: zipCodeSearch,
    formState: { errors: zipCodeError },
  } = useForm();
  const {
    register: bulkRegister,
    handleSubmit: bulkSubmit,
    formState: { errors: bulkError },
  } = useForm();
  const {
    data: serviceData,
    isLoading: serviceLoading,
    isError: serviceError,
  } = useQuery({
    queryKey: ["service"],
    queryFn: () => getService(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ["projectData", page, search, limit, service],
    queryFn: () => getProjectData(page, search, limit, id, service),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading: addLoading } = useMutation(addServiceData, {
    onError: (error) => {
      toast.error(error.response.data.msg);
    },
    onSuccess: () => {
      toast.success("Service data added successfully");
      queryClient.invalidateQueries("projects");
      reset();
    },
  });

  if (isLoading || serviceLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError || serviceError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }

  const onSubmit = (value) => {
    if (images && images.length) {
      let formData = new FormData();
      formData.append("file", images[0]);
      formData.append("sId", value.sid);
      formData.append("pId", id);
      mutate(formData);
    }
  };
  const handelBulkDelete = (value) => {
    mutateBulkDelete({ pId: id, sId: value.sIdBulk });
  };
  const onSearch = (value) => {
    setSearch(value.zipcode);
    setPage(1);
  };
  return (
    <Box mt="6">
      <Flex justify="space-between" p={4}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Projects Details
        </Text>
      </Flex>
      <Box p={4} mt={4} borderRadius="2xl" bg={color}>
        <Flex mt="5" gap={16}>
          <Box
            bg={"white"}
            p={5}
            borderRadius="xl"
            boxShadow={"xl"}
            height={"fit-content"}
            maxW="500px"
          >
            <Box>
              <Text
                fontSize={["1xl", "xl", "xl", "2xl"]}
                as="h1"
                fontWeight="bold"
              >
                Add Data
              </Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex minW={"300px"} gap={8} direction={"column"}>
                <FormControl mt={4}>
                  <FormLabel>Services</FormLabel>
                  <Select
                    {...register("sid", {
                      required: "required",
                    })}
                    placeholder="Select service"
                  >
                    {!serviceLoading &&
                      serviceData?.services?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Select>
                  <FormHelperText color="red">
                    {errors.sid && errors.sid.message}
                  </FormHelperText>
                </FormControl>

                <Box>
                  <FormLabel>Upload Csv</FormLabel>
                  <DropZoneImage
                    maxFiles={1}
                    setImages={setImages}
                    images={images}
                  />
                </Box>
                <Flex justify={"end"}>
                  <Button type="submit" colorScheme="green" variant="solid">
                    {addLoading ? <Spinner /> : "Send"}
                  </Button>
                </Flex>
              </Flex>
            </form>
          </Box>
        </Flex>
      </Box>
      <Box mt={"10"} mb="20">
        <Box mb={5}>
          <Flex justifyContent="end">
            <Flex maxW={"500px"} gap={10}>
              <form onSubmit={zipCodeSearch(onSearch)}>
                <Input
                  w={"100%"}
                  id="zipcode"
                  placeholder="Search by Zipcode"
                  {...zipCodeRegister("zipcode", {
                    required: "required",
                    minLength: {
                      value: 1,
                      message: "Minimum length should be 1",
                    },
                    maxLength: {
                      value: 6,
                      message: "Maximum length should be 6",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only numbers are accepted",
                    },
                  })}
                />
              </form>
              <Select
                w={"100%"}
                onChange={(e) => {
                  setService(e.target.value);
                  setPage(1);
                }}
                placeholder="Select service"
              >
                {!serviceLoading &&
                  serviceData?.services?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Select>
              <Box>
                <Button colorScheme="red" onClick={() => setModal(true)}>
                  Bulk Delete
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Box>
        <TableContainer bg={color} borderRadius="lg">
          <Table>
            <Thead bg={colorz}>
              <Tr>
                <Th p="10px" textAlign="center">
                  zipcode
                </Th>
                <Th p="10px" textAlign="center">
                  city
                </Th>
                <Th p="10px" textAlign="center">
                  Country
                </Th>
                <Th p="10px" textAlign="center">
                  State
                </Th>
                <Th p="10px" textAlign="center">
                  Status
                </Th>
                <Th p="10px" textAlign="center">
                  Created At
                </Th>
                <Th p="10px" textAlign="center">
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                data?.data &&
                data?.data?.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        textAlign="center"
                        p="10px"
                        cursor={"pointer"}
                        onClick={() => navigate(`/project/${item.id}`)}
                      >
                        {item?.zipcode}
                      </Td>
                      <Td textAlign="center" p="10px">
                        {item?.County}
                      </Td>
                      <Td textAlign="center" p="10px">
                        {item?.City}
                      </Td>
                      <Td textAlign="center" p="10px">
                        {item?.State}
                      </Td>
                      <Td textAlign="center" p="10px">
                        {item?.Status}
                      </Td>
                      <Td textAlign="center" p="10px">
                        {item?.createdAt
                          ? moment(item.createdAt).format("DD MMMM YYYY")
                          : "-"}
                      </Td>
                      <Td textAlign="center" p="10px">
                        <IconButton
                          onClick={() => mutateDeleteServiceData(item.id)}
                          colorScheme="red"
                        >
                          <AiFillDelete />
                        </IconButton>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex p={[4, 4, 4, 4]} justify="space-between">
          {data ? (
            <Pagination
              count={data?.count}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          ) : (
            <PaginationLoader />
          )}
        </Flex>
      </Box>
      {console.log(errors)}
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <ModalOverlay />
        <form onSubmit={bulkSubmit(handelBulkDelete)}>
          <ModalContent>
            <ModalHeader>Bulk Delte</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex minW={"300px"} gap={8} direction={"column"}>
                <FormControl mt={4}>
                  <FormLabel>Services</FormLabel>
                  <Select
                    {...bulkRegister("sIdBulk", {
                      required: "required",
                    })}
                    placeholder="Select service"
                  >
                    {!serviceLoading &&
                      serviceData?.services?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Select>
                  <FormHelperText color="red">
                    {bulkError.sIdBulk && bulkError.sIdBulk.message}
                  </FormHelperText>
                </FormControl>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                disabled={loadingBulk}
                colorScheme="red"
                mr={3}
              >
                {loadingBulk && <Spinner size="md" />}Delete
              </Button>
              <Button onClick={() => setModal(false)} colorScheme="blue" mr={3}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Box>
  );
};

export default ProjectDetails;
