import ProtectedRoute from "../../services/ProtectedRoute";
import useAuthStore from "../../store/AuthStore";

const Wrapper = ({ compnent }) => {
  const authCheck = useAuthStore((state) => {
    return state.user ? state.user : false;
  });
  return <ProtectedRoute isAllowed={!!authCheck}>{compnent}</ProtectedRoute>;
};

export default Wrapper;
