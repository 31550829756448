import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DropZoneImage from "../../components/mics/Inputs/DropZone";
import { addProject, getType } from "../../services/apis/touAgencyApi";

const AddProject = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate } = useMutation(addProject, {
    onError: (error) => {
      toast.error(error.response.data.msg);
    },
    onSuccess: () => {
      toast.success("company added successfully");
      queryClient.invalidateQueries("projects");
      navigate("/projects");
    },
  });
  const { data, isLoading } = useQuery("type", getType);

  const onSubmit = (value) => {
    mutate(value);
  };
  return (
    <>
      <Box mt="6">
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold">
            Add Project
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  id="name"
                  placeholder="name"
                  {...register("name", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.name && errors.name.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>price</FormLabel>
                <Input
                  id="description"
                  placeholder="price"
                  {...register("price", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />

                <FormHelperText color="red">
                  {errors.price && errors.price.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>type</FormLabel>

                <Select
                  {...register("type", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                  placeholder="Select option">
                  {!isLoading &&
                    data.data.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Select>
                <FormHelperText color="red">
                  {errors.type && errors.type.message}
                </FormHelperText>
              </FormControl>
              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid">
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddProject;
