import Domain from "../services/Endpoint";
import axios from "axios";
import { setSession } from "../services/jwt.service";
import create from "zustand";
import { toast } from "react-toastify";

const useAuthStore = create((set, get) => ({
  user: null,
  authLoading: false,
  tokenLoading: true,
  authError: null,
  setAuthError: (args) => {
    set({ authError: args });
    setTimeout(() => {
      get().setAuthError(null);
    }, 3000);
  },
  setUser: (args) => set({ user: args }),
  logoutService: () => {
    setSession(null);
    set({ user: null, authLoading: false, tokenLoading: false });
  },
  loginService: async (email, password) => {
    set({ authLoading: true });
    try {
      const rsp = await axios.post(`${Domain}/admin/login`, {
        email,
        password,
      });
      if (rsp.data.result?.user && rsp.data.result?.access_token) {
        setSession(rsp.data.result?.access_token);
        set({ user: rsp.data.result?.user, authLoading: false });
      } else {
        set({ authLoading: false, user: null });
      }
    } catch (error) {
      console.log(error);
      get().setAuthError(error.response.data.error);
      toast.error(error.response.data.error);
      set({ authLoading: false });
    }
  },
  loginWithToken: async () => {
    try {
      const rsp = await axios(`${Domain}/admin/validation`);
      if (rsp.data.result?.user && rsp.data.result?.access_token) {
        setSession(rsp.data.result?.access_token);
        set({ user: rsp.data.result?.user, tokenLoading: false });
      } else {
        set({ tokenLoading: false, user: null });
      }
    } catch (error) {
      console.log(error);
      get().logoutService();
    }
  },
}));
export default useAuthStore;
