import { AiFillHome, AiFillSetting, AiFillTag } from "react-icons/ai";
import { AiFillWindows } from "react-icons/ai";
import { IoWaterOutline } from "react-icons/io5";
import {
  BsDoorOpenFill,
  BsFillPeopleFill,
  BsDatabaseAdd,
} from "react-icons/bs";
import { BiTargetLock } from "react-icons/bi";
import { MdFlourescent, MdRoofing, MdKitchen } from "react-icons/md";
import { MdHouseSiding } from "react-icons/md";
import { GiFloorPolisher, GiLeak } from "react-icons/gi";
import { FaBath, FaHandHoldingMedical } from "react-icons/fa";
import { WiSolarEclipse } from "react-icons/wi";
import { GrTableAdd } from "react-icons/gr";
export const Navlinks = [
  {
    id: 1,
    name: "Profitxl",
    path: "/profitxl",
    icon: <BsFillPeopleFill size="20" />,
    child_routes: null,
  },

  {
    id: 2,
    name: "Project",
    path: "/project",
    icon: <BsFillPeopleFill size="20" />,
    child_routes: null,
  },
  {
    id: 3,
    name: "Services",
    path: "/services",
    icon: <BsFillPeopleFill size="20" />,
    child_routes: null,
  },
  {
    id: 4,
    name: "Jornaya Leads",
    path: "/jornaya-leads",
    icon: <BsFillPeopleFill size="20" />,
    child_routes: null,
  },
  {
    id: 5,
    name: "Medicare Leads",
    path: "/medicare-leads",
    icon: <BiTargetLock size="20" />,
    child_routes: null,
  },
  {
    id: 6,
    name: "Window Leads",
    path: "/window-leads",
    icon: <AiFillWindows size="20" />,
    child_routes: null,
  },
  {
    id: 7,
    name: "Roofing Leads",
    path: "/roofing-leads",
    icon: <MdRoofing size="20" />,
    child_routes: null,
  },
  {
    id: 8,
    name: "Inq Leads",
    path: "/inq-leads",
    icon: <BsFillPeopleFill size="20" />,
    child_routes: null,
  },
  {
    id: 9,
    name: "Kitchen Leads",
    path: "/kitchen-leads",
    icon: <MdKitchen size="20" />,
    child_routes: null,
  },
  {
    id: 10,
    name: "Flooring Leads",
    path: "/flooring-leads",
    icon: <GiFloorPolisher size="20" />,
    child_routes: null,
  },
  {
    id: 11,
    name: "Bathroom Leads",
    path: "/bathroom-leads",
    icon: <FaBath size="20" />,
    child_routes: null,
  },

  {
    id: 12,
    name: "Siding Leads",
    path: "/siding-leads",
    icon: <MdHouseSiding size="20" />,
    child_routes: null,
  },
  {
    id: 14,
    name: "Solar Leads",
    path: "/solar-leads",
    icon: <WiSolarEclipse size="20" />,
    child_routes: null,
  },
  {
    id: 14,
    name: "Buyer Leads",
    path: "/buyerLead",
    icon: <GrTableAdd size="20" />,
    child_routes: null,
  },

  {
    id: 15,
    name: "HVAC",
    path: "/hvac-leads",
    icon: <BsDoorOpenFill size="20" />,
    child_routes: null,
  },
  {
    id: 16,
    name: "Medicare2",
    path: "/medicare-leads2",
    icon: <FaHandHoldingMedical size="20" />,
    child_routes: null,
  },
  {
    id: 17,
    name: "gutter",
    path: "/gutter-leads",
    icon: <GiLeak size="20" />,
    child_routes: null,
  },
  {
    id: 18,
    name: "settlement",
    path: "/settlement-leads",
    icon: <GiLeak size="20" />,
    child_routes: null,
  },
  {
    id: 19,
    name: "Home warranty",
    path: "/home-leads",
    icon: <GiLeak size="20" />,
    child_routes: null,
  },
  {
    id: 20,
    name: "Auto ",
    path: "/auto-leads",
    icon: <GiLeak size="20" />,
    child_routes: null,
  },
  {
    id: 41,
    name: "Medicare Qou",
    path: "/medicare-qoutes-leads",
    icon: <GiLeak size="20" />,
    child_routes: null,
  },


  // {
  //   id: 16,
  //   name: "Water Damage",
  //   path: "/water-damage-leads",
  //   icon: <BsFillPeopleFill size="20" />,
  //   child_routes: null,
  // },
  // {
  //   id: 3,
  //   name: "Verticles",
  //   path: "/verticle",
  //   icon: <BsFillPeopleFill size="20" />,
  //   child_routes: null,
  // },
];
