import {
  Box,
  Flex,
  FormControl,
  Heading,
  Image,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  useDisclosure,
  Center,
  IconButton,
} from "@chakra-ui/react";
import { RiSignalTowerFill } from "react-icons/ri";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getInqLeads, getProjects } from "../../services/apis/touAgencyApi";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineEye,
} from "react-icons/ai";
import ErrorImage from "../../asserts/error.png";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment-timezone";
//d7d7d770
const InqLeads = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1,
  );
  const [limit, setLimit] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("l")) : 10,
  );
  const [search, setSearch] = useState(
    searchParams.get("input") ? searchParams.get("input") : "",
  );
  const [projectId, setProjectId] = useState("");
  const [windowData, setWindowData] = useState(null);

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["inq_leads", page, limit],
    queryFn: () => getInqLeads(page, limit),
    keepPreviousData: true,
  });

  const {
    data: projectData,
    isLoading: projectIsLoading,
    isError: projectIsError,
  } = useQuery({
    queryKey: ["projects", page, limit, search],
    queryFn: () => getProjects(1, 50, search),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  if (isLoading || projectIsLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError || projectIsError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }
  const handelForm = (e) => {
    e.preventDefault();
    setSearch(e.target.input.value);
    setSearchParams({ input: e.target.input.value, p: page, l: limit });
  };

  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Inq leads
        </Text>
      </Flex>
      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Coverage">
                  C
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Accepted">
                  A
                </Tooltip>
              </Th>
              {/* <Th p="10px" textAlign="center">
                  <Tooltip hasArrow label="Support">
                    S
                  </Tooltip>
                </Th> */}
              <Th p="10px" textAlign="center">
                Zipcode
              </Th>
              <Th p="10px" textAlign="center">
                Phone
              </Th>
              <Th p="10px" textAlign="center">
                startDate
              </Th>
              <Th p="10px" textAlign="center">
                Type
              </Th>
              <Th p="10px" textAlign="center">
                Project
              </Th>
              <Th p="10px" textAlign="center">
                Date
              </Th>
              <Th p="10px" textAlign="center">
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data.data &&
              data.data.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td textAlign="center" p="10px">
                      {item.coverage === null ? (
                        <Center>
                          <RiSignalTowerFill color="yellow" />
                        </Center>
                      ) : (
                        <>
                          {item?.coverage === "accepted" ? (
                            <Center>
                              <RiSignalTowerFill color="green" />
                            </Center>
                          ) : (
                            <Center>
                              <HiOutlineStatusOffline color="red" />
                            </Center>
                          )}
                        </>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.coverage === null ? (
                        <Center>
                          <AiOutlineCheckCircle color="yellow" />
                        </Center>
                      ) : (
                        <>
                          {item?.acceptedBid === "accepted" ? (
                            <Center>
                              <AiOutlineCheckCircle color="green" />
                            </Center>
                          ) : (
                            <Center>
                              <AiOutlineCloseCircle color="red" />
                            </Center>
                          )}
                        </>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.zip}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.phoneNumber}
                    </Td>{" "}
                    <Td textAlign="center" p="10px">
                      {item?.startDate !== null
                        ? moment(item?.startDate).format("DD-MM-YYYY")
                        : "-"}
                    </Td>{" "}
                    <Td textAlign="center" p="10px">
                      {item?.type}
                    </Td>{" "}
                    <Td textAlign="center" p="10px">
                      {item?.pInfoRoffingSimple?.name}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {moment
                        .tz(item?.createdAt, "America/New_York")
                        .format("DD-MM-YYYY hh:mm a")}
                    </Td>
                    <Td textAlign="center" p="10px">
                      <Center>
                        <IconButton
                          variant="outline"
                          colorScheme="teal"
                          onClick={() =>
                            navigate(`/inq-leads/details/${item.id}`)
                          }
                        >
                          <AiOutlineEye />
                        </IconButton>
                      </Center>
                    </Td>
                    {/* <Td textAlign="" p="10px">
                        {item?.leads?.error ? item?.leads?.error : "none"}
                      </Td> */}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex p={[4, 4, 4, 4]} justify="space-between">
        {data ? (
          <Pagination
            count={data.count}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <PaginationLoader />
        )}
      </Flex>
    </Box>
  );
};

export default InqLeads;
