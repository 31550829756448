import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  getContact,
  getLogsProfitxl,
  getVerticles,
} from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import dt from "../../profitxl_logs.json";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import { RiSignalTowerFill } from "react-icons/ri";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { BiSupport } from "react-icons/bi";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
//d7d7d770
const Verticle = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["verticless", page, limit],
    queryFn: () => getVerticles(page, limit),
    keepPreviousData: true,
  });
  console.log("=====>", data);
  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }

  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Certicle
        </Text>
      </Flex>
      <Flex justify={"end"} p={4}>
        {/* <form onSubmit={handelForm}>
          <Flex gap={2} align="center">
            <Input
              defaultValue={
                searchParams.get("input") ? searchParams.get("input") : ""
              }
              variant="outline"
              name="input"
              placeholder="Outline"
            />
            <IconButton
              as="button"
              type="submit"
              aria-label="Search database"
              icon={<AiOutlineSearch />}
            />
          </Flex>
        </form> */}
        <Button onClick={() => navigate("/verticle/add")} colorScheme="green">
          Add verticle
        </Button>
      </Flex>
      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              <Th p="10px" textAlign="center">
                id
              </Th>
              <Th p="10px">name</Th>
              <Th p="10px">description</Th>
              <Th p="10px">Created at</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data?.data &&
              data?.data.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td textAlign="center" p="10px">
                      {item.id}
                    </Td>
                    <Td p="10px">{item.name}</Td>
                    <Td p="10px">{item.description}</Td>
                    <Td p="10px">
                      {moment(item.createdAt).format("DD-MM-YYYY")}
                    </Td>
                    {/* <Td textAlign="center" p="10px">
                      {item.tackingNumber ? item.tackingNumber : "-"}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.error ? item.error : "none"}
                    </Td> */}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex p={[4, 4, 4, 4]} justify="space-between">
        {/* <Flex align="center" gap="6">
          {!isLoading && (
            <Select
              value={limit}
              onChange={(e) => setLimit(parseInt(e.target.value))}
            >
              <option value={4}>4</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
          )}
          {data.data && (
            <Text>
              Showing {limit} out 0f {data.count}
            </Text>
          )}
        </Flex> */}
        {data ? (
          <Pagination
            count={data.count}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <PaginationLoader />
        )}
      </Flex>
    </Box>
  );
};

export default Verticle;
