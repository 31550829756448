import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addVerticle } from "../../services/apis/touAgencyApi";

const AddVerticle = () => {
  const [images, setImages] = useState();
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate } = useMutation(addVerticle, {
    onError: (error) => {
      toast.error(error.response.data.msg);
    },
    onSuccess: () => {
      toast.success("company added successfully");
      queryClient.invalidateQueries("projects");
      navigate("/verticle");
    },
  });

  const onSubmit = (value) => {
    console.log(value);
    mutate({ ...value });
  };
  return (
    <>
      <Box mt="6">
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold">
            Add Verticles
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  id="name"
                  placeholder="name"
                  {...register("name", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.name && errors.name.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>description</FormLabel>
                <Textarea
                  id="description"
                  placeholder="description"
                  {...register("description", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />

                <FormHelperText color="red">
                  {errors.description && errors.description.message}
                </FormHelperText>
              </FormControl>
              {/* <FormControl mt={4}>
                <FormLabel>Project Images</FormLabel>
                <DropZoneImage
                  maxFiles={3}
                  height="200px"
                  setImages={(acceptedFiles) => {
                    console.log(acceptedFiles);
                    setImages(acceptedFiles);
                    clearErrors("image");
                  }}
                  images={images}
                />
                <Flex gap={4}>
                  {images &&
                    images.map((item) => (
                      <Box maxW={200} h={200} mt={4}>
                        <Image
                          alt=""
                          objectFit="cover"
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: "px",
                          }}
                          src={URL.createObjectURL(item)}
                        />
                      </Box>
                    ))}
                </Flex>

                <FormHelperText color="red">
                  {errors.image && errors.image.message}
                </FormHelperText>
              </FormControl> */}
              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid">
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddVerticle;
