import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DropZoneImage from "../../components/mics/Inputs/DropZone";
import DisplayModal from "../../components/modals/displayModal";
import { addLead } from "../../services/apis/touAgencyApi";
import greenTick from "../../asserts/greentick.png";
import cross from "../../asserts/cross.png";

const Vert = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState();
  const { type } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(addLead, {
    onError: (error) => {
      toast.error(
        error.response.data.msg || error.response.data.mg || "Some thing wrong",
      );
    },
    onSuccess: (data) => {
      setData(data?.data);
      reset();
      toast.success("lead added successfully");
      onOpen();
    },
  });

  const onSubmit = (value) => {
    mutate({
      ...value,
      pass: value.pass.split("|")[1],
      vert: value.pass.split("|")[0],
    });
  };

  return (
    <>
      <Box mt="6">
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Add {type} Leads
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"
            ></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>zipcode</FormLabel>
                <Input
                  name="zipcode"
                  placeholder="zipcode"
                  type="number"
                  {...register("zipcode", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Min length should be 5",
                    },
                    maxLength: {
                      value: 5,
                      message: "Maxlength should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.zipcode && errors.zipcode.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Verticle</FormLabel>
                <Select
                  name="pass"
                  placeholder="Verticle"
                  type="number"
                  {...register("pass", {
                    required: "This is required",
                  })}
                >
                  {/* <option value="Water Damage|bO5s8HZRrDqlePJY">
                    Water Damage
                  </option> */}
                  <option value="Plumbing|rTM2GQ1lXfXIWMiS">Plumbing</option>
                  <option value="Bath Remodeling|Bp6p52Qz668vP0CZ">
                    Bath Remodeling
                  </option>
                  <option value="Windows|gFvtAgtV6klgVJzn">Windows</option>
                  <option value="Siding|qyJotoc5UGd2svFY">Siding</option>
                  <option value="Gutters|5YUHe62WROpuycNP">Gutters</option>
                  {/* <option value="Gutter Protection|4fseRFw8RMxrciGX">
                    Gutter Protection
                  </option> */}
                  <option value="HVAC|tG2qNhnomCsIaYP9">HVAC</option>
                  <option value="Roofing|pyxhKUs2J0PRmL8u">Roofing</option>
                  {/* <option value="Flooring|A9aKpxCLZuGT9Yes">Flooring</option> */}
                  <option value="Walk In Tubs|KLmsArXhC66eCQ4S">
                    Walk In Tubs
                  </option>
                </Select>
                <FormHelperText color="red">
                  {errors.zipcode && errors.zipcode.message}
                </FormHelperText>
              </FormControl>

              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid"
                >
                  {isLoading ? <Spinner /> : "Submit"}
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>

        <DisplayModal
          isOpen={isOpen}
          onClose={onClose}
          Heading={"Lead Response"}
          content={
            <Flex direction={"column"} gap={2}>
              <Flex justify={"space-between"}>
                <Text fontWeight={600}>Converage</Text>
                <Box>
                  {data?.coverage ? (
                    <img style={{ width: "20px" }} src={greenTick} alt="icon" />
                  ) : (
                    <img style={{ width: "20px" }} src={cross} alt="icon" />
                  )}
                </Box>
              </Flex>
              <Flex justify={"space-between"}>
                <Text fontWeight={600}>Status</Text>
                <Box>
                  {data?.status ? (
                    <img style={{ width: "20px" }} src={greenTick} alt="icon" />
                  ) : (
                    <img style={{ width: "20px" }} src={cross} alt="icon" />
                  )}
                </Box>
              </Flex>
              <Flex justify={"space-between"}>
                <Text fontWeight={600}>Supported</Text>
                <Box>
                  {data?.supported ? (
                    <img style={{ width: "20px" }} src={greenTick} alt="icon" />
                  ) : (
                    <img style={{ width: "20px" }} src={cross} alt="icon" />
                  )}
                </Box>
              </Flex>
              <Flex justify={"space-between"}>
                <Text fontWeight={600}>Ping ID</Text>
                <Box>{data?.ping_id}</Box>
              </Flex>

              <Flex justify={"space-between"}>
                <Text fontWeight={600}>Tracking No.</Text>
                <Box>{data?.tackingNumber}</Box>
              </Flex>
            </Flex>
          }
          action={null}
          loading={false}
        />
      </Box>
    </>
  );
};

export default Vert;
