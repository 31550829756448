import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Heading,
  Image,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getSettlement,
  setelmentPingPost,
} from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import { useNavigate } from "react-router-dom";
import { RiSignalTowerFill } from "react-icons/ri";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { useState } from "react";
import { toast } from "react-toastify";
import DisplayModal from "../../components/modals/displayModal";
//d7d7d770
const SettelmentLeads = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  const [selected, setSelected] = useState(null);

  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["settelment_leads"],
    queryFn: () => getSettlement(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate, isLoading: pingLoading } = useMutation(setelmentPingPost, {
    onError: (error) => {
      toast.error(
        error?.response?.data?.msg
          ? error.response.data.msg
          : "some thing went wrong",
      );
      queryClient.invalidateQueries("settelment_leads");
      onClose();
    },
    onSuccess: (res) => {
      toast.success("Added successfully");
      queryClient.invalidateQueries("settelment_leads");
      onClose();
    },
  });
  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }

  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Debt leads
        </Text>
      </Flex>

      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Coverage">
                  C
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Accepted">
                  A
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                id
              </Th>
              <Th p="10px" textAlign="center">
                Zipcode
              </Th>
              <Th p="10px" textAlign="center">
                Name
              </Th>
              <Th p="10px" textAlign="center">
                Email
              </Th>
              <Th p="10px" textAlign="center">
                Phone
              </Th>
              <Th p="10px" textAlign="center">
                State
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Debt Amount
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Monthly income
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Employment status
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Credit
              </Th>{" "}
              <Th p="10px" textAlign="center">
                address
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data.data &&
              data.data.map((item, index) => {
                return (
                  <Tr
                    cursor={"pointer"}
                    onClick={() => navigate(`${item.id}`)}
                    key={index}
                  >
                    <Td textAlign="center" p="10px">
                      {item.post === null ? (
                        <Center>
                          <RiSignalTowerFill color="yellow" />
                        </Center>
                      ) : (
                        <>
                          {item.post?.coverage === "accepted" ? (
                            <Center>
                              <RiSignalTowerFill color="green" />
                            </Center>
                          ) : (
                            <Center>
                              <HiOutlineStatusOffline color="red" />
                            </Center>
                          )}
                        </>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.post === null ? (
                        <Center>
                          <AiOutlineCheckCircle color="yellow" />
                        </Center>
                      ) : (
                        <>
                          {item.post?.acceptedBid === "accepted" ? (
                            <Center>
                              <AiOutlineCheckCircle color="green" />
                            </Center>
                          ) : (
                            <Center>
                              <AiOutlineCloseCircle color="red" />
                            </Center>
                          )}
                        </>
                      )}
                    </Td>
                    <Td textAlign="" p="10px"></Td>
                    <Td textAlign="" p="10px">
                      {item?.id}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.zip}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.firstName} {item?.lastName}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.email}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.phoneNumber}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.state}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.debtAmount}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.monthlyIncome}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.employmentStatus}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.credit ? item?.credit : "n/a"}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.address}
                    </Td>{" "}
                    {/* <Td textAlign="" p="10px">
                      {item?.timeFrame}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.projectType}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.address}
                    </Td>{" "} */}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      {/* <Flex p={[4, 4, 4, 4]} justify="space-between">
        {data ? (
          <Pagination
            count={data.count}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <PaginationLoader />
        )}
      </Flex> */}
      <DisplayModal
        isOpen={isOpen}
        onClose={onClose}
        Heading="Ping lead"
        content={<Text>Are Your Shure you want to ping this lead</Text>}
        action={() => {
          mutate(selected);
          setSelected(null);
        }}
        loading={pingLoading}
      />
    </Box>
  );
};

export default SettelmentLeads;
