import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  Center,
  useDisclosure,
} from "@chakra-ui/react";
import { RiSignalTowerFill } from "react-icons/ri";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  GutterPingPost,
  getGutterLeads,
} from "../../services/apis/touAgencyApi";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import ErrorImage from "../../asserts/error.png";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import DisplayModal from "../../components/modals/displayModal";
//d7d7d770
const GutterLeads = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1,
  );
  const [limit, setLimit] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("l")) : 10,
  );
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate, isLoading: pingLoading } = useMutation(GutterPingPost, {
    onError: (error) => {
      toast.error(
        error?.response?.data?.msg
          ? error.response.data.msg
          : "some thing went wrong",
      );
      queryClient.invalidateQueries("gutter_leads");
      onClose();
    },
    onSuccess: (res) => {
      toast.success("Added successfully");
      queryClient.invalidateQueries("gutter_leads");
      onClose();
    },
  });
  const { data, isLoading, isError } = useQuery({
    queryKey: ["gutter_leads", page, limit],
    queryFn: () => getGutterLeads(page, limit),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const pingPost = (id) => {
    mutate(id);
  };
  const { isOpen, onClose, onOpen } = useDisclosure();
  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }

  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Gutter leads
        </Text>
      </Flex>

      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Coverage">
                  C
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Accepted">
                  A
                </Tooltip>
              </Th>
              {/* <Th p="10px" textAlign="center">
                  <Tooltip hasArrow label="Support">
                    S
                  </Tooltip>
                </Th> */}
              <Th p="10px" textAlign="center">
                Zipcode
              </Th>
              <Th p="10px" textAlign="center">
                Ping
              </Th>
              <Th p="10px" textAlign="center">
                Name
              </Th>
              <Th p="10px" textAlign="center">
                Email
              </Th>
              <Th p="10px" textAlign="center">
                Phone
              </Th>
              <Th p="10px" textAlign="center">
                State
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Service
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Home Owner
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Call Back Time
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Material Type
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Time Frame
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Project Type
              </Th>
              <Th p="10px" textAlign="center">
                address
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data.data &&
              data.data.map((item, index) => {
                return (
                  <Tr
                    cursor={"pointer"}
                    onClick={() => navigate(`/gutter-leads/${item.id}`)}
                    key={index}
                  >
                    <Td textAlign="center" p="10px">
                      {item.post === null ? (
                        <Center>
                          <RiSignalTowerFill color="yellow" />
                        </Center>
                      ) : (
                        <>
                          {item.post?.coverage === "accepted" ? (
                            <Center>
                              <RiSignalTowerFill color="green" />
                            </Center>
                          ) : (
                            <Center>
                              <HiOutlineStatusOffline color="red" />
                            </Center>
                          )}
                        </>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.post === null ? (
                        <Center>
                          <AiOutlineCheckCircle color="yellow" />
                        </Center>
                      ) : (
                        <>
                          {item.post?.acceptedBid === "accepted" ? (
                            <Center>
                              <AiOutlineCheckCircle color="green" />
                            </Center>
                          ) : (
                            <Center>
                              <AiOutlineCloseCircle color="red" />
                            </Center>
                          )}
                        </>
                      )}
                    </Td>
                    {/* <Td textAlign="center" p="10px">
                        {item.supported ? (
                          <Center>
                            <BiSupport color="green" />
                          </Center>
                        ) : (
                          <Center>
                            <AiOutlineCloseCircle color="red" />
                          </Center>
                        )}
                      </Td> */}
                    <Td textAlign="" p="10px">
                      {item?.zip}
                    </Td>
                    <Td textAlign="" p="10px">
                      <Button
                        disabled={item.post !== null}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelected({ id: item.id });
                          onOpen();
                        }}
                        isLoading={pingLoading}
                      >
                        Ping
                      </Button>
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.firstname} {item?.lastname}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.email}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.phoneNumber}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.state}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.service}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.homeOwener}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.callBtime}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.materialType}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.timeFrame}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.proType}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.address}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <DisplayModal
        isOpen={isOpen}
        onClose={onClose}
        Heading="Ping lead"
        content={<Text>Are Your Shure you want to ping this lead</Text>}
        action={() => {
          pingPost(selected);
          setSelected(null);
        }}
        loading={pingLoading}
      />
      <Flex p={[4, 4, 4, 4]} justify="space-between">
        {data ? (
          <Pagination
            count={data.count}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <PaginationLoader />
        )}
      </Flex>
    </Box>
  );
};

export default GutterLeads;
