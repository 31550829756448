import { Button, Flex, Skeleton } from "@chakra-ui/react";

const PaginationLoader = () => {
  return (
    <Flex gap="5">
      <Skeleton h="43px" w="40" borderRadius="10px" />
      <Skeleton h="43px" w="40" borderRadius="10px" />
      <Skeleton h="43px" w="40" borderRadius="10px" />
      <Skeleton h="43px" w="40" borderRadius="10px" />
      <Skeleton h="43px" w="40" borderRadius="10px" />
    </Flex>
  );
};

export default PaginationLoader;
