import {
  Box,
  Collapse,
  Flex,
  IconButton,
  ScaleFade,
  Text,
  useColorModeValue,
  useOutsideClick,
} from "@chakra-ui/react";
import { useRef } from "react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useAppStore } from "../../store/AppStore";
import { ReactComponent as Logo } from "../../asserts/logo.svg";
import { Navlinks } from "../../Links";

const DrawerComp = ({ isLarger }) => {
  const colors = useColorModeValue("bg.300", "dark.200");
  const itemColors = useColorModeValue("bg.120", "dark.300");
  const itemhover = useColorModeValue("bg.300", "dark.100");
  const { isSidebarOpen, sidebarLinks, onSidebarClose, onOpenSubMenu, user } =
    useAppStore((state) => state);

  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: !isLarger ? onSidebarClose : () => {},
  });
  const color = useColorModeValue("bg.120", "black");

  return (
    <Box
      ref={ref}
      bg={colors}
      maxW="100%"
      w={isSidebarOpen ? "300px" : !isLarger ? "0" : "60px"}
      position={!isLarger && "absolute"}
      transition={".4s width"}
      overflow="hidden"
      minH="100vh"
      zIndex="9999"
      boxShadow="md"
    >
      <Box mt="3">
        <Flex justify="center">
          <IconButton
            aria-label="loggo"
            bg="none"
            color="brand.100"
            boxShadow="2xl"
            icon={
              <Logo
                size="40"
                style={{
                  width: "60px",
                }}
              />
            }
          />
        </Flex>

        <Box
          h="calc(100vh - 200px)"
          overflowY="auto"
          overflowX="hidden"
          my="8"
          sx={{
            "&::-webkit-scrollbar-track": {
              bg: "transparent",
            },
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              bg: "brand.100",
              borderRadius: "20px",
            },
          }}
        >
          <Flex direction="column">
            {sidebarLinks.map((x) =>
              x.child_routes ? (
                <Box key={x.id}>
                  <Flex
                    justify={isSidebarOpen ? "flex-start" : "center"}
                    _hover={{ bg: "red" }}
                    px={isSidebarOpen ? 6 : 0}
                    py="4"
                    w="100%"
                    cursor="pointer"
                    borderLeftRadius="2xl"
                    mb="1"
                    onClick={() => (isSidebarOpen ? onOpenSubMenu(x.id) : null)}
                  >
                    <Box mb="1">{x.icon}</Box>
                    <Flex
                      flex="1"
                      style={{ display: isSidebarOpen ? "flex" : "none" }}
                      justify="space-between"
                    >
                      <ScaleFade in={isSidebarOpen} initialScale={0}>
                        <Text ml="3" fontSize="sm">
                          {x.name}
                        </Text>
                      </ScaleFade>
                      {x.open ? <FiChevronDown /> : <FiChevronRight />}
                    </Flex>
                  </Flex>
                  <Collapse in={x.open} animateOpacity>
                    <Box pl="14">
                      {x.child_routes.map((cr) => (
                        <NavLink to={cr.path} key={cr.id}>
                          {({ isActive }) => (
                            <ScaleFade
                              in={isSidebarOpen}
                              initialScale={0}
                              style={{
                                display: isSidebarOpen ? "block" : "none",
                              }}
                              mb="2"
                            >
                              <Flex
                                _hover={{ color: "red" }}
                                py="2"
                                w="100%"
                                cursor="pointer"
                                color={isActive ? "teal.600" : "none"}
                                mb="1"
                              >
                                <Text fontSize="sm">{cr.name}</Text>
                              </Flex>
                            </ScaleFade>
                          )}
                        </NavLink>
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              ) : (
                <NavLink style={{ padding: "0px 10px" }} to={x.path} key={x.id}>
                  {({ isActive }) => (
                    <Flex
                      justify={isSidebarOpen ? "flex-start" : "center"}
                      _hover={{ bg: { itemhover }, color: "brand.100" }}
                      px={isSidebarOpen ? 6 : 0}
                      py="4"
                      w="100%"
                      cursor="pointer"
                      color={isActive ? "white" : { itemColors }}
                      borderRadius="2xl"
                      bg={isActive ? color : "none"}
                      mb="1"
                    >
                      <Box mb="1">{x.icon}</Box>
                      <ScaleFade
                        in={isSidebarOpen}
                        initialScale={0}
                        style={{ display: isSidebarOpen ? "block" : "none" }}
                      >
                        <Text ml="3" fontSize="sm">
                          {x.name}
                        </Text>
                      </ScaleFade>
                    </Flex>
                  )}
                </NavLink>
              ),
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default DrawerComp;
