import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getmedicarePx } from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { RiSignalTowerFill } from "react-icons/ri";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { BiSupport } from "react-icons/bi";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import { useSearchParams } from "react-router-dom";
import DisplayModal from "../../components/modals/displayModal";
//d7d7d770
const MedicareLeads2 = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1,
  );

  const [select, setSelect] = useState({});
  const { data, isLoading, isError } = useQuery({
    queryKey: ["getmedicarePx", page, 10],
    queryFn: () => getmedicarePx(page, 10),
    keepPreviousData: true,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }

  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Medicare leads 2
        </Text>
      </Flex>

      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Coverage">
                  C
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Accepted">
                  A
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                Error
              </Th>
              <Th p="10px" textAlign="center">
                Name
              </Th>
              <Th p="10px" textAlign="center">
                Email
              </Th>
              <Th p="10px" textAlign="center">
                Phone
              </Th>
              <Th p="10px" textAlign="center">
                Age
              </Th>
              <Th p="10px" textAlign="center">
                Insuranced
              </Th>

              <Th p="10px" textAlign="center">
                Zipcode
              </Th>

              <Th p="10px" textAlign="center">
                other
              </Th>
              <Th p="10px" textAlign="center">
                response
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data.data &&
              data.data.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td textAlign="center" p="10px">
                      {item?.coverage === "posted" ? (
                        <Center>
                          <RiSignalTowerFill color="green" />
                        </Center>
                      ) : (
                        <Center>
                          <HiOutlineStatusOffline color="red" />
                        </Center>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.acceptedBid === "accepted" ? (
                        <Center>
                          <AiOutlineCheckCircle color="green" />
                        </Center>
                      ) : (
                        <Center>
                          <AiOutlineCloseCircle color="red" />
                        </Center>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.error ? item.error : "none"}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.firstname} {item?.lastname}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.email}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.phone}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.age}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.insuranced}
                    </Td>

                    <Td textAlign="center" p="10px">
                      {item?.zip}
                    </Td>

                    <Td textAlign="center" p="10px">
                      <Button
                        onClick={() => {
                          setSelect(item.other);
                          onOpen();
                        }}
                      >
                        View
                      </Button>
                    </Td>
                    <Td textAlign="center" p="10px">
                      <Button
                        onClick={() => {
                          setSelect(item.response);
                          onOpen();
                        }}
                      >
                        View
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex p={[4, 4, 4, 4]} justify="space-between">
        {/* <Flex align="center" gap="6">
            {!isLoading && (
              <Select
                value={limit}
                onChange={(e) => setLimit(parseInt(e.target.value))}
              >
                <option value={4}>4</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </Select>
            )}
            {data.data && (
              <Text>
                Showing {limit} out 0f {data.count}
              </Text>
            )}
          </Flex> */}
        <DisplayModal
          isOpen={isOpen}
          onClose={() => {
            setSelect({});
            onClose();
          }}
          content={<pre id="myText">{JSON.stringify(select, null, 4)}</pre>}
        />
        {data ? (
          <Pagination
            count={data.count}
            limit={10}
            page={page}
            setPage={setPage}
          />
        ) : (
          <PaginationLoader />
        )}
      </Flex>
    </Box>
  );
};

export default MedicareLeads2;
