import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getProjects } from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addProject } from "../../services/apis/touAgencyApi";
import { states } from "../../services/constants";
import moment from "moment";
//d7d7d770
const Projects = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1
  );
  const [limit, setLimit] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("l")) : 10
  );
  const [search, setSearch] = useState(
    searchParams.get("input") ? searchParams.get("input") : ""
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["projects", page, limit, search],
    queryFn: () => getProjects(page, limit, search),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate, isLoading: addLoading } = useMutation(addProject, {
    onError: (error) => {
      toast.error(error.response.data.msg);
    },
    onSuccess: () => {
      toast.success("Projects added successfully");
      queryClient.invalidateQueries("projects");
      reset();
    },
  });

  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }
  const handelForm = (e) => {
    e.preventDefault();
    setSearch(e?.target?.value);
    setSearchParams({ input: e?.target?.value, p: page, l: limit });
  };
  const onSubmit = (value) => {
    mutate(value);
  };
  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Projects
        </Text>
      </Flex>
      <Flex justify={"space-between"} flexWrap="wrap" gap={5}>
        <Box
          bg={"white"}
          p={5}
          // mt={"73px"}
          borderRadius="xl"
          boxShadow={"xl"}
          height={"fit-content"}>
          <Box>
            <Text
              fontSize={["1xl", "xl", "xl", "2xl"]}
              as="h1"
              fontWeight="bold">
              Add Project
            </Text>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex minW={"300px"} gap={8} direction={"column"}>
              <FormControl mt={4}>
                <FormLabel>Name</FormLabel>
                <Input
                  id="name"
                  placeholder="name"
                  {...register("name", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.name && errors.name.message}
                </FormHelperText>
              </FormControl>
              <Flex>
                <FormControl>
                  <FormLabel>Price</FormLabel>
                  <Input
                    id="price"
                    placeholder="price"
                    {...register("price", {
                      required: "required",
                      minLength: {
                        value: 1,
                        message: "Minimum length should be 1",
                      },
                      maxLength: {
                        value: 6,
                        message: "Maximum length should be 6",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Only numbers are accepted",
                      },
                    })}
                  />
                  <FormHelperText color="red">
                    {errors.price && errors.price.message}
                  </FormHelperText>
                </FormControl>
              </Flex>
              <Flex justify={"end"}>
                <Button type="submit" colorScheme="green" variant="solid">
                  {addLoading ? <Spinner /> : "Send"}
                </Button>
              </Flex>
            </Flex>
          </form>
        </Box>
        <Box w={["100%", "100%", "100%", "1000px"]}>
          {/* <Flex justify={"end"} p={4}>
            <Flex gap={2} align="center">
              <Select onChange={handelForm} placeholder="Search By State">
                {!isLoading &&
                  states.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </Select>
            </Flex>
          </Flex> */}
          <TableContainer bg={color} borderRadius="lg">
            <Table>
              <Thead bg={colorz}>
                <Tr>
                  <Th p="10px" textAlign="center">
                    Name
                  </Th>
                  <Th p="10px" textAlign="center">
                    Price
                  </Th>
                  <Th p="10px" textAlign="center">
                    Status
                  </Th>
                  <Th p="10px" textAlign="center">
                    Created At
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isLoading &&
                  data?.data &&
                  data?.data?.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td
                          textAlign="center"
                          p="10px"
                          cursor={"pointer"}
                          onClick={() => navigate(`/project/${item.id}`)}>
                          {item?.name ? item.name : "-"}
                        </Td>
                        <Td textAlign="center" p="10px">
                          {item?.price ? item.price : "-"}
                        </Td>
                        <Td textAlign="center" p="10px">
                          {item?.status ? item.status : "-"}
                        </Td>
                        <Td textAlign="center" p="10px">
                          {item?.createdAt
                            ? moment(item.createdAt).format("DD MMMM YYYY")
                            : "-"}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            {/* <Flex align="center" gap="6">
              {!isLoading && (
                <Select
                  value={limit}
                  onChange={(e) => setLimit(parseInt(e.target.value))}>
                  <option value={4}>4</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </Select>
              )}
              {data?.data && (
                <Text>
                  Showing {limit} out 0f {data?.count}
                </Text>
              )}
            </Flex> */}
            {data ? (
              <Pagination
                count={data?.count}
                limit={limit}
                page={page}
                setPage={setPage}
              />
            ) : (
              <PaginationLoader />
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Projects;
