import { Box, Flex, Heading, Image, Spinner } from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getInqLeads } from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import { useParams } from "react-router-dom";
import moment from "moment";

const InqLeadsDetail = () => {
  const color = useColorModeValue("bg.300", "dark.400");

  const { id } = useParams();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["inq_leads", 1, 10, id],
    queryFn: () => getInqLeads(1, 10, id),
    keepPreviousData: true,
  });

  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }

  let windoWData = data?.data?.length && data?.data[0];
  return (
    <Box mt="6">
      <Flex justify="space-between" p={4}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Inq Details
        </Text>
      </Flex>

      <Box p={4} mt={4} borderRadius="2xl" bg={color}>
        <Flex flexWrap={"wrap"} gap="5">
          <Box>
            <Text fontSize={"20px"} fontWeight="600" mb="5">
              Lead Detail
            </Text>
            <Box
              color={"grey"}
              border={"1px solid grey"}
              borderRadius="md"
              boxShadow={"xl"}
              p="3"
              w={["400px"]}
              //   h={["200px"]}
            >
              <Flex justify={"space-between"}>
                <Text fontWeight={600} fontSize={"18px"}>
                  ZipCode
                </Text>
                <Text fontSize={"18px"}>{windoWData?.zip}</Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text fontWeight={600} fontSize={"18px"}>
                  Coverage
                </Text>
                <Text fontSize={"18px"}>
                  {windoWData?.postR === null
                    ? "pending"
                    : windoWData?.coverage}
                </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text fontWeight={600} fontSize={"18px"}>
                  Accepted Bid
                </Text>
                <Text fontSize={"18px"}>
                  {windoWData?.acceptedBid === null
                    ? "pending"
                    : windoWData?.acceptedBid}
                </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text fontWeight={600} fontSize={"18px"}>
                  start date
                </Text>
                <Text fontSize={"18px"}>
                  {windoWData?.startDate === null
                    ? "-"
                    : moment(windoWData?.startDate).format("DD-MM-YYYY")}
                </Text>
              </Flex>
            </Box>
          </Box>
          <Box>
            <Text fontSize={"20px"} fontWeight="600" mb="5">
              Response Detail
            </Text>
            <Box
              color={"grey"}
              border={"1px solid grey"}
              borderRadius="md"
              boxShadow={"xl"}
              p="3"
              w={["400px"]}
              //   h={["200px"]}
            >
              {Object.entries(windoWData.response).map(([key, value]) => (
                <Flex key={key} gap={"10"} justify={"space-between"}>
                  <Text fontWeight={600} fontSize={"18px"}>
                    {key}
                  </Text>
                  <Text textAlign={"end"} color={"grey"} fontSize={"18px"}>
                    {value}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Box>
          <Box>
            <Text fontSize={"20px"} fontWeight="600" mb="5">
              Personal Detail
            </Text>
            <Box
              border={"1px solid grey"}
              borderRadius="md"
              boxShadow={"xl"}
              p="3"
              w={["400px"]}
              color={"grey"}
              //   h={["200px"]}
            >
              <Flex justify={"space-between"}>
                <Text fontWeight={600} fontSize={"18px"}>
                  Phone
                </Text>
                <Text fontSize={"18px"}>{windoWData?.phoneNumber}</Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text fontWeight={600} fontSize={"18px"}>
                  Zip
                </Text>
                <Text fontSize={"18px"}>{windoWData?.zip}</Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text fontWeight={600} fontSize={"18px"}>
                  Project
                </Text>
                <Text fontSize={"18px"}>
                  {windoWData?.pInfoRoffingSimple.name}
                </Text>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default InqLeadsDetail;
