import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import RandomLoader from "../../components/loader/randomLoader.jsx";
import {
  addMedicare,
  addMedicare2,
  getUserProjects,
} from "../../services/apis/touAgencyApi.js";
import { currentlyInsured, states } from "../../services/constants.js";
import ErrorImage from "../../asserts/error.png";
import DisplayModal from "../../components/modals/displayModal.js";
import { useState } from "react";
import { FcApproval, FcDisapprove } from "react-icons/fc";

const AddMedicare2 = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addMedResData, setAddMedResData] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { mutate, isLoading } = useMutation(addMedicare2, {
    onError: (error) => {
      if (Array.isArray(error.response.data.error)) {
        error.response.data.error.map((item) => toast.error(item));
      }
      toast.error(error.response.data.msg || "some thing went wrong");
    },
    onSuccess: (res) => {
      toast.success("Added successfully");
      reset();
      setAddMedResData(
        res?.result?.data?.callInfo?.caller_id_formatted?._text || "",
      );
      onOpen();
    },
  });

  const onSubmit = (value) => {
    mutate(value);
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RandomLoader />
          <Text fontSize={["1xl", "3xl", "4xl", "6xl"]} position={"absolute"}>
            Loading
          </Text>
        </div>
      )}
      <Box mt="6" mb="20" position={"relative"}>
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Add Medicare 2
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"
            ></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  id="firstName"
                  placeholder="firstName"
                  {...register("firstName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.firstName && errors.firstName.message}
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  id="lastName"
                  placeholder="lastName"
                  {...register("lastName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.lastName && errors.lastName.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>State</FormLabel>
                <Select
                  {...register("state", {
                    required: "This is required",
                  })}
                  placeholder="Select state"
                >
                  {states.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.state && errors.state.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  id="zip"
                  placeholder="zip"
                  {...register("zip", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.zip && errors.zip.message}
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>City</FormLabel>
                <Input
                  id="city"
                  placeholder="city"
                  {...register("city", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.city && errors.city.message}
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  id="phone"
                  placeholder="phone"
                  {...register("phone", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.phone && errors.phone.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  id="address"
                  placeholder="address"
                  {...register("address", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.address && errors.address.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  id="email"
                  placeholder="email"
                  {...register("email", {
                    required: "required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "invalid email address",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.email && errors.email.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Age</FormLabel>
                <Input
                  id="age"
                  placeholder="age"
                  {...register("age", {
                    required: "required",
                  })}
                />
                <FormHelperText color="red">
                  {errors.age && errors.age.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Date of birth</FormLabel>
                <Input
                  id="dob"
                  placeholder="dob"
                  type="date"
                  {...register("dob", {
                    required: "required",
                    // pattern: {
                    //   value:
                    //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    //   message: "invalid age address",
                    // },
                  })}
                />
                <FormHelperText color="red">
                  {errors.dob && errors.dob.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel> Currently Insured? </FormLabel>
                <Select
                  {...register("insuranced", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select insuranced"
                >
                  {["yes", "no"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.insuranced && errors.insuranced.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel> Gender </FormLabel>
                <Select
                  {...register("gender", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select insuranced"
                >
                  {["Male", "Female"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.gender && errors.gender.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel> Marital status </FormLabel>
                <Select
                  {...register("maritalStatus", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select maritalStatus"
                >
                  {[
                    "Divorced",
                    "Married",
                    "Separated",
                    "Single",
                    "Widowed",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.maritalStatus && errors.maritalStatus.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel> relation </FormLabel>
                <Select
                  {...register("relation", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select relation"
                >
                  {[
                    "Self",
                    "Spouse",
                    "Child",
                    "Sibling",
                    "Parent",
                    "Grandparent",
                    "Grandchild",
                    "Other",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.relation && errors.relation.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel> firstTimeBuyer </FormLabel>
                <Select
                  {...register("firstTimeBuyer", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select firstTimeBuyer"
                >
                  {["Yes", "No"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.firstTimeBuyer && errors.firstTimeBuyer.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>-- income --</FormLabel>
                <Select
                  {...register("income", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select income"
                >
                  {[
                    "Below $30,000",
                    "$30,000 - $44,999",
                    "$45,000 - $59,999",
                    "$60,000 - $74,999",
                    "Above $75,000",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.income && errors.income.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel> product </FormLabel>
                <Select
                  {...register("product", {
                    required: "This is required",
                  })}
                  placeholder="Select product"
                >
                  {[
                    "(MO) Medicare Original",
                    "(MA) Medicare Advantage",
                    "(MD) Medicare Part D",
                    "(MS) Medicare Supplement",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.product && errors.product.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel> Denid coverage </FormLabel>
                <Select
                  {...register("denidCoverage", {
                    required: "This is required",
                  })}
                  placeholder="Select denidCoverage"
                >
                  {["Yes", "No"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.denidCoverage && errors.denidCoverage.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel> hospitalized </FormLabel>
                <Select
                  {...register("hospitalized", {
                    required: "This is required",
                  })}
                  placeholder="Select hospitalized"
                >
                  {["Yes", "No"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.hospitalized && errors.hospitalized.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel> pregnant </FormLabel>
                <Select
                  {...register("pregnant", {
                    required: "This is required",
                  })}
                  placeholder="Select pregnant"
                >
                  {["Yes", "No"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.pregnant && errors.pregnant.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel> smoker </FormLabel>
                <Select
                  {...register("smoker", {
                    required: "This is required",
                  })}
                  placeholder="Select smoker"
                >
                  {["Yes", "No"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.smoker && errors.smoker.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel> alcoholabstain </FormLabel>
                <Select
                  {...register("alcoholabstain", {
                    required: "This is required",
                  })}
                  placeholder="Select alcoholabstain"
                >
                  {["Yes", "No"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.alcoholabstain && errors.alcoholabstain.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel> Sub Id </FormLabel>
                <Input
                  id="subId"
                  placeholder="subId"
                  {...register("subId", {
                    required: "This is required",
                  })}
                />
                <FormHelperText color="red">
                  {errors.subId && errors.subId.message}
                </FormHelperText>
              </FormControl>

              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid"
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
      <DisplayModal
        isOpen={isOpen}
        onClose={onClose}
        Heading={"Lead Info"}
        content={
          <Box>
            <Flex
              gap={5}
              justifyContent={"space-between"}
              alignItems="center"
              mb="5"
            >
              <Box>
                <Text fontSize={"lg"} fontWeight="500">
                  {addMedResData
                    ? "Lead Accepted Successfully"
                    : "Lead Not Accepted"}
                </Text>
              </Box>
              <Box>
                {addMedResData ? (
                  <FcApproval size={30} />
                ) : (
                  <FcDisapprove size={30} />
                )}
              </Box>
            </Flex>
            <Box>
              <Text>{addMedResData}</Text>
            </Box>
          </Box>
        }
        action={null}
      />
    </>
  );
};

export default AddMedicare2;
