import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import RandomLoader from "../../components/loader/randomLoader.jsx";
import { addHome, addHomeInc } from "../../services/apis/touAgencyApi.js";
import { states } from "../../services/constants.js";

const AddHomeInc = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate: solar, isLoading: solarLoading } = useMutation(addHomeInc, {
    onError: (error) => {
      if (Array.isArray(error.response.data.error)) {
        error.response.data.error.map((item) => toast.error(item));
      } else {
        toast.error(error.response.data.msg || "some thing went wrong");
      }
    },
    onSuccess: (res) => {
      toast.success("Added successfully");
      reset();
    },
  });

  const onSubmit = (value) => {
    solar(value);
  };

  return (
    <>
      {solarLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RandomLoader />
          <Text fontSize={["1xl", "3xl", "4xl", "6xl"]} position={"absolute"}>
            Loading
          </Text>
        </div>
      ) : null}
      <Box mt="6" mb="20" position={"relative"}>
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Add Home Insurance
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"
            ></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  id="firstName"
                  placeholder="firstName"
                  {...register("firstName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.firstName && errors.firstName.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  id="lastName"
                  placeholder="lastName"
                  {...register("lastName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.lastName && errors.lastName.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>House Type</FormLabel>
                <Select
                  {...register("houseType", {
                    required: "This is required",
                  })}
                  placeholder="Select houseType"
                >
                  {[
                    "Single family",
                    "Apartment",
                    "Condo",
                    "Town home",
                    "Mobile",
                    "other",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.houseType && errors.houseType.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Current Insurer</FormLabel>
                <Select
                  {...register("currentInc", {
                    required: "This is required",
                  })}
                  placeholder="Select option"
                >
                  {[
                    "All state",
                    "American family",
                    "Eric Insurance",
                    "Farmers",
                    "Liberty Mutual",
                    "Progressive",
                    "Statefarm",
                    "Travellers",
                    "USAA",
                    "Other",
                    "Not Insured",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.currentInc && errors.currentInc.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Age of home</FormLabel>
                <Select
                  {...register("ageOfHome", {
                    required: "This is required",
                  })}
                  placeholder="Select option"
                >
                  {[
                    "New",
                    "1-10 yrs",
                    "11-20 yrs",
                    "21-50 yrs",
                    "over 50 yrs",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.ageOfHome && errors.ageOfHome.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Square foot</FormLabel>
                <Select
                  {...register("size", {
                    required: "This is required",
                  })}
                  placeholder="Select option"
                >
                  {[
                    "500 or less",
                    "501-1000",
                    "1000-2000",
                    "2000-3000",
                    "3000-5000",
                    "5001+",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.size && errors.size.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Number of stories</FormLabel>
                <Select
                  {...register("stories", {
                    required: "This is required",
                  })}
                  placeholder="Select option"
                >
                  {["1", "2+"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.stories && errors.stories.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>State</FormLabel>
                <Select
                  {...register("state", {
                    required: "This is required",
                  })}
                  placeholder="Select state"
                >
                  {states.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.state && errors.state.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>City</FormLabel>
                <Input
                  id="City"
                  placeholder="City"
                  {...register("city", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.City && errors.City.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  id="zip"
                  placeholder="zip"
                  {...register("zip", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.zip && errors.zip.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  id="phoneNumber"
                  placeholder="phoneNumber"
                  {...register("phoneNumber", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  id="email"
                  placeholder="email"
                  {...register("email", {
                    required: "required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "invalid email address",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.email && errors.email.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  id="address"
                  placeholder="address"
                  {...register("address", {
                    required: "required",
                  })}
                />
                <FormHelperText color="red">
                  {errors.address && errors.address.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>subId</FormLabel>
                <Input id="subId" placeholder="subId" {...register("subId")} />
                <FormHelperText color="red">
                  {errors.subId && errors.subId.message}
                </FormHelperText>
              </FormControl>
              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid"
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddHomeInc;
