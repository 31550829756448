import {
  Box,
  Flex,
  Heading,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getBuyerData } from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import { useState } from "react";
//d7d7d770
const BuyerData = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  const [isValid, setIsValid] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["solar_leads"],
    queryFn: () => getBuyerData(),
    keepPreviousData: true,
    enabled: isValid,
  });
  if (!isValid) {
    return (
      <Modal isOpen={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pin verification</ModalHeader>
          <ModalBody>
            <Center px={20}>
              <HStack>
                <PinInput
                  onChange={(value) => value === "51214" && setIsValid(true)}
                  manageFocus={true}
                  otp
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }

  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Buyer
        </Text>
      </Flex>

      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              {/* <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Coverage">
                  C
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Accepted">
                  A
                </Tooltip>
              </Th> */}
              {/* <Th p="10px" textAlign="center">
                  <Tooltip hasArrow label="Support">
                    S
                  </Tooltip>
                </Th> */}
              <Th p="10px" textAlign="center">
                id
              </Th>
              <Th p="10px" textAlign="center">
                buyer
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Zipcode
              </Th>
              <Th p="10px" textAlign="center">
                Name
              </Th>
              <Th p="10px" textAlign="center">
                Email
              </Th>
              <Th p="10px" textAlign="center">
                Phone
              </Th>
              <Th p="10px" textAlign="center">
                State
              </Th>{" "}
              <Th p="10px" textAlign="center">
                utility Provider
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Home Owner
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Roof Shade
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Avg Electric Bill
              </Th>{" "}
              {/* <Th p="10px" textAlign="center">
                Project Type
              </Th> */}
              <Th p="10px" textAlign="center">
                address
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data &&
              data.map((item, index) => {
                return (
                  <Tr cursor={"pointer"} key={index}>
                    <Td textAlign="" p="10px">
                      {item?.id}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.buyerName}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.zip}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.firstName} {item?.lastName}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.email}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.phoneNumber}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.state}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.electricCityProvider}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.homeOwener}
                    </Td>{" "}
                    {/* <Td textAlign="" p="10px">
                      {item?.callBtime}
                    </Td>{" "} */}
                    <Td textAlign="" p="10px">
                      {item?.roofingShades}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.monthlyPowerBill}
                    </Td>{" "}
                    {/* <Td textAlign="" p="10px">
                      {item?.projectType}
                    </Td>{" "} */}
                    <Td textAlign="" p="10px">
                      {item?.address}
                    </Td>{" "}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BuyerData;
