import {
  Box,
  Center,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getDataLotLeads, getProjects } from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import dt from "../../profitxl_logs.json";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import { RiSignalTowerFill } from "react-icons/ri";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { BiSupport } from "react-icons/bi";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import { useLocation, useSearchParams } from "react-router-dom";
//d7d7d770
const MedicareLeads = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1,
  );
  const [limit, setLimit] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("l")) : 10,
  );
  const [search, setSearch] = useState(
    searchParams.get("input") ? searchParams.get("input") : "",
  );

  const [projectId, setProjectId] = useState("");
  const { data, isLoading, isError } = useQuery({
    queryKey: ["data_lots_keads", page, limit, projectId],
    queryFn: () => getDataLotLeads(page, limit, projectId),
    keepPreviousData: true,
  });

  const {
    data: projectData,
    isLoading: projectIsLoading,
    isError: projectIsError,
  } = useQuery({
    queryKey: ["projects", page, limit, search],
    queryFn: () => getProjects(1, 50, search),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  if (isLoading || projectIsLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError || projectIsError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }
  const handelForm = (e) => {
    e.preventDefault();
    setSearch(e.target.input.value);
    setSearchParams({ input: e.target.input.value, p: page, l: limit });
  };

  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Medicare leads
        </Text>
      </Flex>
      <Flex justify={"end"} p={4}>
        <form onSubmit={handelForm}>
          <Flex gap={2} align="center">
            <FormControl mt={4}>
              <Select
                onChange={(e) =>
                  setProjectId(e.target.value === "all" ? "" : e.target.value)
                }
                placeholder="Select project"
              >
                <option value={"all"}>All</option>
                {projectData?.data?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
        </form>
      </Flex>
      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Coverage">
                  C
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Accepted">
                  A
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Support">
                  S
                </Tooltip>
              </Th>

              <Th p="10px" textAlign="center">
                Name
              </Th>
              <Th p="10px" textAlign="center">
                Email
              </Th>
              <Th p="10px" textAlign="center">
                Phone
              </Th>
              <Th p="10px" textAlign="center">
                Age
              </Th>
              <Th p="10px" textAlign="center">
                Insuranced
              </Th>

              <Th p="10px" textAlign="center">
                Zipcode
              </Th>
              <Th p="10px" textAlign="center">
                amount
              </Th>
              {/*  */}
              <Th p="10px" textAlign="center">
                caller id
              </Th>
              <Th p="10px" textAlign="center">
                Quote id
              </Th>

              <Th p="10px" textAlign="center">
                Error
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data.data &&
              data.data.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td textAlign="center" p="10px">
                      {item.leads?.coverage ? (
                        <Center>
                          <RiSignalTowerFill color="green" />
                        </Center>
                      ) : (
                        <Center>
                          <HiOutlineStatusOffline color="red" />
                        </Center>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.leads?.acceptedBid ? (
                        <Center>
                          <AiOutlineCheckCircle color="green" />
                        </Center>
                      ) : (
                        <Center>
                          <AiOutlineCloseCircle color="red" />
                        </Center>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.supported ? (
                        <Center>
                          <BiSupport color="green" />
                        </Center>
                      ) : (
                        <Center>
                          <AiOutlineCloseCircle color="red" />
                        </Center>
                      )}
                    </Td>

                    <Td textAlign="" p="10px">
                      {item?.firstname} {item?.lastname}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.email}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.phoneNumber}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.age}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item?.insuranced}
                    </Td>

                    <Td textAlign="" p="10px">
                      {item?.leads?.zip}
                    </Td>

                    <Td textAlign="" p="10px">
                      {item?.leads?.price}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.leads?.callerId}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.leads?.qouteId}
                    </Td>

                    <Td textAlign="" p="10px">
                      {item?.leads?.error ? item?.leads?.error : "none"}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex p={[4, 4, 4, 4]} justify="space-between">
        {/* <Flex align="center" gap="6">
            {!isLoading && (
              <Select
                value={limit}
                onChange={(e) => setLimit(parseInt(e.target.value))}
              >
                <option value={4}>4</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </Select>
            )}
            {data.data && (
              <Text>
                Showing {limit} out 0f {data.count}
              </Text>
            )}
          </Flex> */}
        {data ? (
          <Pagination
            count={data.count}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <PaginationLoader />
        )}
      </Flex>
    </Box>
  );
};

export default MedicareLeads;
