import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import RandomLoader from "../../components/loader/randomLoader.jsx";
import { addAuto } from "../../services/apis/touAgencyApi.js";
import { states } from "../../services/constants.js";

const AddAuto = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate: solar, isLoading: solarLoading } = useMutation(addAuto, {
    onError: (error) => {
      if (Array.isArray(error.response.data.error)) {
        error.response.data.error.map((item) => toast.error(item));
      } else {
        toast.error(error.response.data.msg || "some thing went wrong");
      }
    },
    onSuccess: (res) => {
      toast.success("Added successfully");
      reset();
    },
  });

  const onSubmit = (value) => {
    solar(value);
  };

  return (
    <>
      {solarLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RandomLoader />
          <Text fontSize={["1xl", "3xl", "4xl", "6xl"]} position={"absolute"}>
            Loading
          </Text>
        </div>
      ) : null}
      <Box mt="6" mb="20" position={"relative"}>
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Add Auto Insurance
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"
            ></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  id="firstName"
                  placeholder="firstName"
                  {...register("firstName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.firstName && errors.firstName.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  id="lastName"
                  placeholder="lastName"
                  {...register("lastName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.lastName && errors.lastName.message}
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Year</FormLabel>
                <Input
                  id="year"
                  placeholder="year"
                  {...register("year", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.year && errors.year.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Make</FormLabel>
                <Input
                  id="make"
                  placeholder="make"
                  {...register("make", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.make && errors.make.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Model</FormLabel>
                <Input
                  id="model"
                  placeholder="model"
                  {...register("model", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.model && errors.model.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>SR22</FormLabel>
                <Select
                  {...register("sr22", {
                    required: "This is required",
                  })}
                  placeholder="Select sr22"
                >
                  {["YES", "NO"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.sr22 && errors.sr22.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Current Insurance Provider</FormLabel>
                <Select
                  {...register("currentInsuranceProvider", {
                    required: "This is required",
                  })}
                  placeholder="Select currentInsuranceProvider"
                >
                  {[
                    "aaa",
                    "auto_insurance_specialists",
                    "Allstate",
                    "geico",
                    "direct_auto",
                    "farmers",
                    "nationwide",
                    "plymouth_rock",
                    "progressive",
                    "state_farm",
                    "travelers",
                    "usaa",
                    "other",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.currentInsuranceProvider &&
                    errors.currentInsuranceProvider.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Home Owner</FormLabel>
                <Select
                  {...register("homeOwner", {
                    required: "This is required",
                  })}
                  placeholder="Select homeOwner"
                >
                  {["YES ", "NO"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.homeOwner && errors.homeOwner.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>DUI / Accident Last 6 Months?</FormLabel>
                <Select
                  {...register("dui", {
                    required: "This is required",
                  })}
                  placeholder="Select dui"
                >
                  {["YES ", "NO"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.dui && errors.dui.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Insured From Past 6 Months</FormLabel>
                <Select
                  {...register("past", {
                    required: "This is required",
                  })}
                  placeholder="Select option"
                >
                  {["YES ", "NO"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.past && errors.past.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>State</FormLabel>
                <Select
                  {...register("state", {
                    required: "This is required",
                  })}
                  placeholder="Select state"
                >
                  {states.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.state && errors.state.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>City</FormLabel>
                <Input
                  id="City"
                  placeholder="City"
                  {...register("city", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.City && errors.City.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  id="zip"
                  placeholder="zip"
                  {...register("zip", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.zip && errors.zip.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  id="phoneNumber"
                  placeholder="phoneNumber"
                  {...register("phoneNumber", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  id="email"
                  placeholder="email"
                  {...register("email", {
                    required: "required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "invalid email address",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.email && errors.email.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  id="address"
                  placeholder="address"
                  {...register("address", {
                    required: "required",
                  })}
                />
                <FormHelperText color="red">
                  {errors.address && errors.address.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>subId</FormLabel>
                <Input id="subId" placeholder="subId" {...register("subId")} />
                <FormHelperText color="red">
                  {errors.subId && errors.subId.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>D ID</FormLabel>
                <Select
                  {...register("dId", {
                    required: "This is required",
                  })}
                  placeholder="Select Owner"
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => (
                    <option key={key} value={item}>
                      D-{item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.dId && errors.dId.message}
                </FormHelperText>
              </FormControl>
              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid"
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddAuto;
