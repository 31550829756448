import {
  Box,
  Flex,
  Container,
  useMediaQuery,
  useColorModeValue,
} from "@chakra-ui/react";
import Navbar from "../navbars/Navbar";
import DrawerComp from "../drawer/Drawer";
import { useEffect } from "react";
import { useAppStore } from "../../store/AppStore";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const [isLarger] = useMediaQuery("(min-width: 768px)");
  const { isSidebarOpen, onSidebarOpen } = useAppStore((state) => state);
  const location = useLocation();
  const color = useColorModeValue("bg.100", "dark.100");
  useEffect(() => {
    if (isLarger) {
      onSidebarOpen();
    } else return;
  }, []);
  const render = () => {
    return (
      location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname.includes("/registerationForm")
    );
  };
  return (
    <div>
      {render() ? null : (
        <Box position="relative">
          <DrawerOverLay isLarger={isLarger} isSidebarOpen={isSidebarOpen} />
          <Flex>
            <DrawerComp isLarger={isLarger} />
            <Box
              flex="1"
              minH="calc(100vh)"
              bg={color}
              position="relative"
              overflowY="hidden">
              <Box
                h="100vh"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar-track": {
                    bg: "transparent",
                  },
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    bg: "brand.100",
                    borderRadius: "20px",
                  },
                }}>
                <Navbar />
                <Container maxW={1440}>{children}</Container>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
      {render() && children}
    </div>
  );
};

const DrawerOverLay = ({ isLarger, isSidebarOpen }) => {
  return (
    <Box>
      {!isLarger && isSidebarOpen && (
        <Box
          w="100vw"
          h="100vh"
          bg="bg.200"
          position="fixed"
          top="0"
          left="0"
          zIndex="1300"
        />
      )}
    </Box>
  );
};

export default Layout;
