import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  position,
  Select,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { searchZipCode } from "../../services/apis/touAgencyApi";
import { toast } from "react-toastify";
import greenTick from "../../asserts/greentick.png";

//d7d7d770
const SearchZip = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  const [flouSearch, setFlouSearch] = useState();

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const {
    mutate,
    isLoading: zipLoading,
    data: zipData,
  } = useMutation(searchZipCode, {
    onError: (error) => {
      toast.error(error.response.data.msg);
    },
    onSuccess: () => {
      toast.success("Data Fetched");
      queryClient.invalidateQueries("projects");
      reset();
    },
  });

  const onSubmit = async (value) => {
    mutate(value);
  };

  return (
    <>
      <Box mt="6">
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Search Zipcode
          </Text>
        </Flex>
        <SimpleGrid gap={4} columns={[1, 2]}>
          <Box borderRadius="lg" mt="4" bg={color}>
            <Flex p={4} justify="space-between">
              <Text
                color={colorz}
                fontSize={["1xl", "2xl"]}
                as="h1"
                fontWeight="bold"
              ></Text>
            </Flex>
            <Box p={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                  <FormLabel>Zipcode</FormLabel>
                  <Input
                    id="zipcode"
                    placeholder="zipcode"
                    {...register("zipcode", {
                      required: "This is required",
                      minLength: {
                        value: 4,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                  <FormHelperText color="red">
                    {errors.zipcode && errors.zipcode.message}
                  </FormHelperText>
                </FormControl>
                <Flex justify="end">
                  <Button
                    disabled={zipLoading}
                    type="submit"
                    mt={4}
                    colorScheme="green"
                    variant="solid"
                  >
                    {zipLoading ? <Spinner /> : "Submit"}
                  </Button>
                </Flex>
              </form>
            </Box>
          </Box>
          <Box borderRadius="lg" mt="4" bg={color}>
            <Box p={4}>
              <Flex gap={4}>
                <Text fontSize="2xl" fontWeight="bold" as={"h2"}>
                  ZipCode:
                </Text>
                <Text fontSize="4xl" fontWeight="bold" as={"p"}>
                  {flouSearch && flouSearch.zipcode}
                </Text>
              </Flex>
              <Flex>
                <Flex gap={4} mt="3">
                  <Text w={"90px"} fontSize="xl" as={"h2"}>
                    City:
                  </Text>
                  <Text fontSize="xl" as={"p"}>
                    {zipData?.data?.length
                      ? zipData?.data[0]?.project[0]?.City
                      : "--"}
                  </Text>
                </Flex>
              </Flex>
              <Flex gap={4}>
                <Text w={"90px"} fontSize="xl" as={"h2"}>
                  Country:
                </Text>
                <Text fontSize="xl" as={"p"}>
                  {zipData?.data?.length
                    ? zipData?.data[0]?.project[0]?.County
                    : "--"}
                </Text>
              </Flex>
              <Flex gap={4}>
                <Text w={"90px"} fontSize="xl" as={"h2"}>
                  State:
                </Text>
                <Text fontSize="xl" as={"p"}>
                  {zipData?.data?.length
                    ? zipData?.data[0]?.project[0]?.State
                    : "--"}
                </Text>
              </Flex>
            </Box>
          </Box>
        </SimpleGrid>
        {/* <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={4} justify="space-between"></Flex>
        </Box> */}
        <Text fontSize="3xl" mt="16" fontWeight={"bold"} as={"h2"}>
          Zipcode Data:
        </Text>
        {zipData?.data?.length ? (
          <>
            {zipData?.data?.map((buyer) => (
              <Box
                bg={"white"}
                my="10"
                border="1px solid #D3D3D3"
                p="10"
                borderRadius={"xl"}
                boxShadow="xl"
              >
                <Flex>
                  <Text fontSize="xl" fontWeight={"semibold"} as={"h2"}>
                    {buyer?.name}&nbsp;
                  </Text>
                </Flex>
                <Flex mt="3" gap="10" flexWrap={"wrap"}>
                  {buyer?.project?.map((porj) => (
                    <Box
                      bg="white"
                      w="300px"
                      h="100px"
                      px="5"
                      py="3"
                      border={"1px solid rgb(50,205,50, 0.6)"}
                      position={"relative"}
                      borderRadius={"lg"}
                      boxShadow={"lg"}
                    >
                      <img
                        style={{
                          width: "15px",
                          position: "absolute",
                          right: 15,
                        }}
                        src={greenTick}
                        alt="tick"
                        tick
                        w="100%"
                      />
                      <Flex
                        justifyContent={"space-between"}
                        alignItems="center"
                        h={"100%"}
                      >
                        <Text fontSize="md" fontWeight={"semibold"} as={"h2"}>
                          Service
                        </Text>
                        <Text fontSize="md" as={"h2"}>
                          {porj?.service?.name}&nbsp;
                        </Text>
                        <Text fontSize="md" as={"h2"}>
                          {porj?.service?.phoneNumber}&nbsp;
                        </Text>
                      </Flex>
                    </Box>
                  ))}
                </Flex>
              </Box>
            ))}
          </>
        ) : (
          <Text fontSize="2xl" mt="5" fontWeight={"medium"} as={"h2"}>
            Nothing to show!!!
          </Text>
        )}
      </Box>
    </>
  );
};

export default SearchZip;
