import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import RandomLoader from "../../components/loader/randomLoader.jsx";
import { addMedicare, getUserProjects } from "../../services/apis/touAgencyApi";
import { currentlyInsured, states } from "../../services/constants";
import ErrorImage from "../../asserts/error.png";
import DisplayModal from "../../components/modals/displayModal";
import { useState } from "react";
import { FcApproval, FcDisapprove } from "react-icons/fc";

const AddMedicare = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addMedResData, setAddMedResData] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { mutate, isLoading } = useMutation(addMedicare, {
    onError: (error) => {
      toast.error(error.response.data.msg || "some thing went wrong");
    },
    onSuccess: (res) => {
      toast.success("Added successfully");
      reset();
      setAddMedResData(
        res?.result?.data?.callInfo?.caller_id_formatted?._text || ""
      );
      onOpen();
    },
  });

  const {
    data: projectData,
    isLoading: projectIsLoading,
    isError,
  } = useQuery({
    queryKey: ["userprojects"],
    queryFn: () => getUserProjects(1, 50),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const onSubmit = (value) => {
    mutate(value);
  };

  if (projectIsLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <RandomLoader />
          <Text fontSize={["1xl", "3xl", "4xl", "6xl"]} position={"absolute"}>
            Loading
          </Text>
        </div>
      )}
      <Box mt="6" mb="20" position={"relative"}>
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold">
            Add Medicare
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  id="firstName"
                  placeholder="firstName"
                  {...register("firstName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.firstName && errors.firstName.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  id="lastName"
                  placeholder="lastName"
                  {...register("lastName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.lastName && errors.lastName.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Project</FormLabel>
                <Select
                  {...register("pId", {
                    required: "This is required",
                  })}
                  placeholder="Select project">
                  {projectData?.data?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.pId && errors.pId.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>State</FormLabel>
                <Select
                  {...register("state", {
                    required: "This is required",
                  })}
                  placeholder="Select state">
                  {states.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.state && errors.state.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  id="zip"
                  placeholder="zip"
                  {...register("zip", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.zip && errors.zip.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  id="phoneNumber"
                  placeholder="phoneNumber"
                  {...register("phoneNumber", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  id="email"
                  placeholder="email"
                  {...register("email", {
                    required: "required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "invalid email address",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.email && errors.email.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>-- Currently Insured? --</FormLabel>
                <Select
                  {...register("insuranced", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select insuranced">
                  {currentlyInsured.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.insuranced && errors.insuranced.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Is your age between 50 to 85 years?</FormLabel>
                <Select
                  {...register("age", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select">
                  <option value={"yes"}>yes</option>
                  <option value={"no"}>No</option>
                </Select>
                <FormHelperText color="red">
                  {errors.age && errors.age.message}
                </FormHelperText>
              </FormControl>
              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid">
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
      <DisplayModal
        isOpen={isOpen}
        onClose={onClose}
        Heading={"Lead Info"}
        content={
          <Box>
            <Flex
              gap={5}
              justifyContent={"space-between"}
              alignItems="center"
              mb="5">
              <Box>
                <Text fontSize={"lg"} fontWeight="500">
                  {addMedResData
                    ? "Lead Accepted Successfully"
                    : "Lead Not Accepted"}
                </Text>
              </Box>
              <Box>
                {addMedResData ? (
                  <FcApproval size={30} />
                ) : (
                  <FcDisapprove size={30} />
                )}
              </Box>
            </Flex>
            <Box>
              <Text>{addMedResData}</Text>
            </Box>
          </Box>
        }
        action={null}
      />
    </>
  );
};

export default AddMedicare;
