import { Center, useColorModeValue } from "@chakra-ui/react";
import { ReactComponent as Logo } from "../../asserts/logo.svg";
const FullPageLoader = () => {
  const color = useColorModeValue("bg.100", "dark.400");
  return (
    <Center bg={color} h={"100vh"}>
      <Logo width={200} />
    </Center>
  );
};

export default FullPageLoader;
