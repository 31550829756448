import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  Show,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuthStore from "../../store/AuthStore";
import { MdAlternateEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import Logo from "../../asserts/logoBig.svg";
import cover from "../../asserts/loginCover.jpg";

const Login = () => {
  const navigate = useNavigate();
  const { loginService, authLoading, user, authError } = useAuthStore(
    (state) => state
  );
  console.log(user);
  useEffect(() => {
    if (!!user) {
      navigate("/profitxl");
    }
  }, [user]);
  const onLogin = async (e) => {
    e.preventDefault();
    let email = e.target.email?.value;
    let password = e.target.password?.value;
    if (!email || !password) return;
    loginService(email, password);
  };
  return (
    <>
      {authLoading && <Progress size="xs" isIndeterminate />}
      {authError && (
        <Alert status="error" position="absolute">
          <AlertIcon />
          <AlertTitle>{authError}</AlertTitle>
        </Alert>
      )}
      <SimpleGrid columns={[1, 1, 2]} h="100vh">
        <Show above="md">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            bgImage={`url(${cover})`}
            objectFit="cover"
            w="100%"
            h="100%">
            <img
              src={Logo}
              alt=""
              style={{ width: "320px", zIndex: 1, margin: "0px auto" }}
            />
            <Center></Center>
          </Box>
        </Show>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          w="100%"
          h="100%">
          <Box w="full" sx={{ maxWidth: "420px", margin: "0px auto" }}>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                alignItem: "center",
              }}
              onSubmit={onLogin}>
              <Text fontSize="4xl" textAlign="center" fontWeight="700">
                Login
              </Text>
              <InputGroup>
                <InputRightElement
                  pointerEvents="none"
                  children={<MdAlternateEmail />}
                />
                <Input
                  placeholder="email"
                  name="email"
                  type="email"
                  variant="filled"
                  required
                  size="lg"
                />
              </InputGroup>
              <InputGroup>
                <InputRightElement
                  pointerEvents="none"
                  children={<RiLockPasswordLine />}
                />
                <Input
                  placeholder="password"
                  name="password"
                  type="password"
                  variant="filled"
                  size="lg"
                  required
                />
              </InputGroup>
              <Button variant="primary" type="submit">
                Login
              </Button>

              <Link style={{ color: "#0645AD" }} to="/">
                Proceed as agent
              </Link>

              {authLoading ? <h2>Loading...</h2> : null}
            </form>
          </Box>
        </Box>
      </SimpleGrid>
    </>
  );
};

export default Login;
