import { Box, Flex, styled, Text, useColorModeValue } from "@chakra-ui/react";
import { useMemo } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { AiOutlineCloudUpload } from "react-icons/ai";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  height: "100%",
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DropZoneImage = ({ setImages, images, height, maxFiles }) => {
  const colorz = useColorModeValue("bg.100", "dark.600");

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    // accept: { "image/*": [] },
    maxFiles: maxFiles,
    onDrop: (acceptedFiles) => setImages(acceptedFiles),
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  console.log(fileRejections);
  return (
    <Flex gap="10px">
      <Flex
        {...getRootProps(style)}
        fontSize="5xl"
        align="center"
        bg={colorz}
        color="bg.120"
        justify="center"
        width="100px"
        h={height}>
        <input {...getInputProps()} />
        <AiOutlineCloudUpload />
      </Flex>
      <Flex gap="10px">
        {fileRejections.map((item) => (
          <Text color="red">{`${item.file.name}: ${item.errors[0].message}`}</Text>
        ))}
      </Flex>
    </Flex>
  );
};

export default DropZoneImage;
