import {
  Box,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { useAppStore } from "../../store/AppStore";
import useAuthStore from "../../store/AuthStore";
import { MdDarkMode } from "react-icons/md";
import { BsSunFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const color = useColorModeValue("bg.300", "dark.200");
  const colors = useColorModeValue("bg.100", "dark.300");
  const colorz = useColorModeValue("bg.120", "dark.400");
  const { isSidebarOpen, onSidebarClose, onSidebarOpen } = useAppStore(
    (state) => state
  );
  const navigate = useNavigate();
  const { logoutService, user } = useAuthStore((state) => state);
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box w="100%">
      <Flex bg={color} h="68px" align="center" px="4" justify="space-between">
        <Flex align="center">
          <IconButton
            css={{
              WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
            }}
            aria-label="close-btn"
            icon={
              isSidebarOpen ? (
                // <Logo/>
                <AiOutlineMenuFold size="22" />
              ) : (
                <AiOutlineMenuUnfold size="22" />
              )
            }
            _focus={{ outline: "none" }}
            onClick={isSidebarOpen ? onSidebarClose : onSidebarOpen}
            size="md"
            bg={colorz}
            _hover={{ bg: { color } }}
            _active={{ bg: { colors } }}
            color={colors}
            borderRadius="full"
            boxShadow="md"
          />
          <Box ml="4">
            <Text fontWeight="bold" fontSize="xl" color="brand.100">
              Outright Admin
            </Text>
          </Box>
        </Flex>
        <Center gap={4}>
          {colorMode == "dark" ? (
            <IconButton onClick={toggleColorMode} icon={<MdDarkMode />} />
          ) : (
            <IconButton onClick={toggleColorMode} icon={<BsSunFill />} />
          )}
          <Menu>
            <MenuButton as={IconButton} icon={<GiHamburgerMenu />} />
            {user ? (
              <MenuList>
                <MenuItem>Accout Details</MenuItem>
                <MenuItem onClick={() => logoutService()}>Logout</MenuItem>
              </MenuList>
            ) : (
              <MenuList>
                <MenuItem onClick={() => navigate("/login")}>Login</MenuItem>
              </MenuList>
            )}
          </Menu>
        </Center>
      </Flex>
    </Box>
  );
};

export default Navbar;
