export const states = [
  { value: "AK", label: "AK" },
  { value: "AL", label: "AL" },
  { value: "AR", label: "AR" },
  { value: "AZ", label: "AZ" },
  { value: "CA", label: "CA" },
  { value: "CO", label: "CO" },
  { value: "CT", label: "CT" },
  { value: "DE", label: "DE" },
  { value: "FL", label: "FL" },
  { value: "GA", label: "GA" },
  { value: "HI", label: "HI" },
  { value: "IA", label: "IA" },
  { value: "ID", label: "ID" },
  { value: "IL", label: "IL" },
  { value: "IN", label: "IN" },
  { value: "KS", label: "KS" },
  { value: "KY", label: "KY" },
  { value: "LA", label: "LA" },
  { value: "MA", label: "MA" },
  { value: "MD", label: "MD" },
  { value: "ME", label: "ME" },
  { value: "MI", label: "MI" },
  { value: "MN", label: "MN" },
  { value: "MO", label: "MO" },
  { value: "MS", label: "MS" },
  { value: "MT", label: "MT" },
  { value: "NC", label: "NC" },
  { value: "ND", label: "ND" },
  { value: "NE", label: "NE" },
  { value: "NH", label: "NH" },
  { value: "NJ", label: "NJ" },
  { value: "NM", label: "NM" },
  { value: "NV", label: "NV" },
  { value: "NY", label: "NY" },
  { value: "OH", label: "OH" },
  { value: "OK", label: "OK" },
  { value: "OR", label: "OR" },
  { value: "PA", label: "PA" },
  { value: "RI", label: "RI" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "TN", label: "TN" },
  { value: "TX", label: "TX" },
  { value: "UT", label: "UT" },
  { value: "VT", label: "VT" },
  { value: "VA", label: "VA" },
  { value: "WA", label: "WA" },
  { value: "WI", label: "WI" },
  { value: "WV", label: "WV" },
  { value: "WY", label: "WY" },
];

/// madicare
export const currentlyInsured = [
  { value: "currently not insured", label: "Currently Not Insured" },
  { value: "cigna", label: "Cigna" },
  { value: "assurant", label: "Assurant" },
  { value: "ameriprise", label: "Ameriprise" },
  { value: "american republic", label: "American Republic" },
  { value: "american family", label: "American Family" },
  { value: "aflac", label: "Aflac" },
  { value: "aetna", label: "Aetna" },
  { value: "other", label: "Other" },
];
export const income = [
  "Below $30,000",
  "$30,000 – $44,999",
  "$45,000 – $59,999",
  "$60,000 – $74,999",
  "Above $75,000",
];

export const maritalStatus = [
  "Single",
  "Married",
  "Separated",
  "Divorced",
  "Widowed",
];

export const product = [
  "Original Medicare",
  "Medicare Advantage (MA)",
  "Medicare Part D (MD)",
  "Medicare Supplement",
];

export const employmentStatus = [
  "Employeed",
  "Government",
  "Homemaker",
  "Retired",
  "Student Living w/ Parents",
  "Student not Living w/ Parents",
  "Unemployed",
  "Military",
  "Retail",
  "Sales",
  "Marketing",
  "IT",
  "Medical",
  "Unknown",
  "BusinessOwner",
  "Student",
  "SalesInside",
  "SalesOutside",
  "Scientist",
  "OtherTechnical",
  "MilitaryEnlisted",
  "Architect",
  "Other",
];
