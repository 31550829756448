import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import RandomLoader from "../../components/loader/randomLoader.jsx";
import { addInqPost, getUserProjects } from "../../services/apis/touAgencyApi";

const InqPosting = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { mutate, isLoading } = useMutation(addInqPost, {
    onError: (error) => {
      toast.error(error.response.data.msg || "some thing went wrong");
    },
    onSuccess: (res) => {
      toast.success("Added successfully");
      reset();
    },
  });

  const {
    data: projectData,
    isLoading: projectIsLoading,
    isError,
  } = useQuery({
    queryKey: ["userprojects"],
    queryFn: () => getUserProjects(1, 50),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const onSubmit = (value) => {
    mutate(value);
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RandomLoader />
          <Text fontSize={["1xl", "3xl", "4xl", "6xl"]} position={"absolute"}>
            Loading
          </Text>
        </div>
      )}
      <Box mt="6" mb="20" position={"relative"}>
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Add INQ Posting
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"
            ></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  id="phoneNumber"
                  placeholder="phoneNumber"
                  {...register("phoneNumber", {
                    required: "This is required",
                    minLength: {
                      value: 9,
                      message: "Minimum length should be 12",
                    },
                    maxLength: {
                      value: 10,
                      message: "Minimum length should be 13",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Start Date</FormLabel>
                <Input
                  id="startDate"
                  placeholder="startDate"
                  type="date"
                  {...register("startDate", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.startDate && errors.startDate.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Project</FormLabel>
                <Select
                  {...register("pId", {
                    required: "This is required",
                  })}
                  placeholder="Select project"
                >
                  {projectData?.data?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.pId && errors.pId.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Type</FormLabel>
                <Select
                  {...register("type", {
                    required: "This is required",
                  })}
                  placeholder="Select Type"
                >
                  <option value="Roofing">roofing </option>
                  <option value="Plumbing">Plumbing</option>
                  <option value="Windows">Windows</option>
                  <option value="Water Damage">Water Damage</option>
                  <option value="Gutters">Gutters</option>
                  <option value="Bathroom Remodel">Bathroom Remodel</option>
                </Select>
                <FormHelperText color="red">
                  {errors.type && errors.type.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  id="zip"
                  placeholder="zip"
                  {...register("zip", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.zip && errors.zip.message}
                </FormHelperText>
              </FormControl>
              {/* <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  id="address"
                  placeholder="address"
                  {...register("address", {
                    required: "required",
                  })}
                />
                <FormHelperText color="red">
                  {errors.address && errors.address.message}
                </FormHelperText>
              </FormControl> */}
              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid"
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InqPosting;
