import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import RandomLoader from "../../components/loader/randomLoader.jsx";
import {
  addMedicare,
  addMedicareQoutes,
  getUserProjects,
} from "../../services/apis/touAgencyApi.js";
import { currentlyInsured, employmentStatus, income, maritalStatus, product, states } from "../../services/constants.js";
import ErrorImage from "../../asserts/error.png";
import DisplayModal from "../../components/modals/displayModal.js";
import { useState } from "react";
import { FcApproval, FcDisapprove } from "react-icons/fc";

const AddMedicareQoutes = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addMedResData, setAddMedResData] = useState("");
  const {
    register,
    handleSubmit,
	getValues,
    reset,
    formState: { errors },
  } = useForm();
  const { mutate, isLoading } = useMutation(addMedicareQoutes, {
    onError: (error) => {
      toast.error(error.response.data.msg || "some thing went wrong");
    },
    onSuccess: (res) => {
      toast.success("Added successfully");
      reset();
      setAddMedResData(
        res?.result?.data?.callInfo?.caller_id_formatted?._text || "",
      );
      onOpen();
    },
  });
	const [insuranced, setInsuranced] = useState("No");
  const onSubmit = (value) => {
    mutate(value);
  };

   return (
    <>
       <Box mt="6" mb="20" position={"relative"}>
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Add Medicare Qoutes
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"
            ></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  id="firstName"
                  placeholder="firstName"
                  {...register("firstName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.firstName && errors.firstName.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  id="lastName"
                  placeholder="lastName"
                  {...register("lastName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.lastName && errors.lastName.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>State</FormLabel>
                <Select
                  {...register("state", {
                    required: "This is required",
                  })}
                  placeholder="Select state"
                >
                  {states.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.state && errors.state.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  id="zip"
                  placeholder="zip"
                  {...register("zip", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.zip && errors.zip.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>City</FormLabel>
                <Input
                  id="zip"
                  placeholder="city"
                  {...register("city", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.city && errors.city.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  id="zip"
                  placeholder="address"
                  {...register("address", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.address&& errors.address.message}
                </FormHelperText>
              </FormControl>

			  <FormControl>
                <FormLabel>dob</FormLabel>
                <Input
                  id="zip"
				  type="date"
                  placeholder="dob"
                  {...register("dob", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.dob && errors.dob.message}
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  id="phoneNumber"
                  placeholder="phoneNumber"
                  {...register("phoneNumber", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  id="email"
                  placeholder="email"
                  {...register("email", {
                    required: "required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "invalid email address",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.email && errors.email.message}
                </FormHelperText>
              </FormControl>
			  <FormControl mt={4}>
                <FormLabel>Income</FormLabel>
                <Select
                  {...register("income", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select house hold income"
                >
                  {income.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.insuranced && errors.insuranced.message}
                </FormHelperText>
              </FormControl>
			  <FormControl mt={4}>
                <FormLabel>House Size</FormLabel>
                <Input
                  {...register("houseHoldSize", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="House hold income"
               / >
                
                <FormHelperText color="red">
                  {errors.houseHoldSize && errors.houseHoldSize.message}
                </FormHelperText>
              </FormControl>
			  <FormControl mt={4}>
                <FormLabel>Gender</FormLabel>
                <Select
                  {...register("gender", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select gender"
                >
                  {['Male', "Female"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.Gender&& errors.Gender.message}
                </FormHelperText>
              </FormControl>

			  <FormControl mt={4}>
                <FormLabel>Marital Status</FormLabel>
                <Select
                  {...register("maritalStatus", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select marital status"
                >
                  {maritalStatus.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.maritalStatus && errors.maritalStatus.message}
                </FormHelperText>
              </FormControl>
			  <FormControl mt={4}>
                <FormLabel>Product</FormLabel>
                <Select
                  {...register("product", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select product"
                >
                  {product.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.product && errors.product.message}
                </FormHelperText>
              </FormControl>

			  <FormControl mt={4}>
                <FormLabel>-- Currently Insured? --</FormLabel>
                <Select
                  {...register("insuranced", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
					  onChange: (event)=>{setInsuranced(event.target.value)}
                  })}
                  placeholder="Select insuranced"
                >
                  {["Yes", "No"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.insuranced && errors.insuranced.message}
                </FormHelperText>
              </FormControl>

				{getValues("insuranced") === "Yes" && <><FormControl mt={4}>
						<FormLabel>Insurance Company</FormLabel>
						<Select
							{...register("insuranceCompany", {
								required: "This is required",
								minLength: {
									value: 2,
									message: "Minimum length should be 2",
								},
							})}
							placeholder="Select insuranced"
						>
							{currentlyInsured.map((item, index) => (
								<option key={index} value={item.value}>
									{item.label}
								</option>
							))}
						</Select>
						<FormHelperText color="red">
							{errors.insuranceCompany&& errors.insuranceCompany.message}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<FormLabel>Policy Start</FormLabel>
						<Input
							id="policyStart"
							type="date"
							placeholder="policyStart"
							{...register("policyStart", {
								required: "This is required",
							})}
						/>
						<FormHelperText color="red">
							{errors.policyStart && errors.policyStart.message}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<FormLabel>Policy End</FormLabel>
						<Input
							id="policyEnd"
							type="date"
							placeholder="policyEnd"
							{...register("policyEnd", {
								required: "This is required",
							})}
						/>
						<FormHelperText color="red">
							{errors.policyEnd && errors.policyEnd.message}
						</FormHelperText>
					</FormControl>
				</>
					}				
              			  <FormControl mt={4}>
                <FormLabel>occupation</FormLabel>
                <Select
                  {...register("occupation", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Select occupation"
                >
                  {employmentStatus.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.occupation && errors.occupation.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Age</FormLabel>
                <Input
                  {...register("age", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="Age"
                />
                <FormHelperText color="red">
                  {errors.age && errors.age.message}
                </FormHelperText>
              </FormControl>
			  <FormControl mt={4}>
                <FormLabel>Height Feet</FormLabel>
                <Input
                  {...register("heightFeet", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
					type="number"
                  placeholder="height in feet"
                />
                <FormHelperText color="red">
                  {errors.heightFeet && errors.heightFeet.message}
                </FormHelperText>
              </FormControl>

			  <FormControl mt={4}>
                <FormLabel>Height Inch</FormLabel>
                <Input
                  {...register("heightInch", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="height in inches"
                />
                <FormHelperText color="red">
                  {errors.heightInch && errors.heightInch.message}
                </FormHelperText>
              </FormControl>
			  <FormControl mt={4}>
                <FormLabel>Weight</FormLabel>
                <Input
                  {...register("weight", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                  placeholder="weight"
                />
                <FormHelperText color="red">
                  {errors.weight && errors.weight.message}
                </FormHelperText>
              </FormControl>
              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid"
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
      <DisplayModal
        isOpen={isOpen}
        onClose={onClose}
        Heading={"Lead Info"}
        content={
          <Box>
            <Flex
              gap={5}
              justifyContent={"space-between"}
              alignItems="center"
              mb="5"
            >
              <Box>
                <Text fontSize={"lg"} fontWeight="500">
                  {addMedResData
                    ? "Lead Accepted Successfully"
                    : "Lead Not Accepted"}
                </Text>
              </Box>
              <Box>
                {addMedResData ? (
                  <FcApproval size={30} />
                ) : (
                  <FcDisapprove size={30} />
                )}
              </Box>
            </Flex>
            <Box>
              <Text>{addMedResData}</Text>
            </Box>
          </Box>
        }
        action={null}
      />
    </>
  );
};

export default AddMedicareQoutes;
