import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";
import reportWebVitals from "./reportWebVitals";
import Auth from "./components/Auth/Auth";
import {
  customColors,
  customComponents,
  customConfig,
  customFonts,
} from "./services/theme";

const theme = extendTheme({
  fonts: customFonts,
  colors: customColors,
  components: customComponents,
  config: customConfig,
});
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Auth>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </Auth>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
