import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import RandomLoader from "../../components/loader/randomLoader.jsx";
import { addSettlement } from "../../services/apis/touAgencyApi";
import { states } from "../../services/constants";

const AddSettellment = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { mutate, isLoading } = useMutation(addSettlement, {
    onError: (error) => {
      if (Array.isArray(error.response.data.error)) {
        error.response.data.error.map((item) => toast.error(item));
      } else {
        toast.error(error.response.data.msg || "some thing went wrong");
      }
    },
    onSuccess: (res) => {
      toast.success("Added successfully");
      reset();
    },
  });

  const onSubmit = (value) => {
    mutate(value);
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RandomLoader />
          <Text fontSize={["1xl", "3xl", "4xl", "6xl"]} position={"absolute"}>
            Loading
          </Text>
        </div>
      )}
      <Box mt="6" mb="20" position={"relative"}>
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Add Settlement
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"
            ></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  id="firstName"
                  placeholder="firstName"
                  {...register("firstName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.firstName && errors.firstName.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  id="lastName"
                  placeholder="lastName"
                  {...register("lastName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.lastName && errors.lastName.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Debt Amount</FormLabel>
                <Select
                  {...register("debtAmount", {
                    required: "This is required",
                  })}
                  placeholder="Select Debt Amount"
                >
                  {[
                    "$0 - $4,999",
                    "$5,000 - $7,499",
                    "$7,500 - $9,999",
                    "$10,000 - $14,999",
                    "$15,000 - $19,999",
                    "$20,000 - $29,999",
                    "$30,000 - $39,999",
                    "$40,000 - $49,999",
                    "$50,000 - $59,999",
                    "$60,000 - $69,999",
                    "$70,000 - $79,999",
                    "$80,000 - $89,999",
                    "$90,000 - $99,999",
                    "$100,000+",
                  ].map((item, key) => (
                    <option value={item} key={key}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.debtAmount && errors.debtAmount.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Monthly Income</FormLabel>
                <Select
                  {...register("monthlyIncome", {
                    required: "This is required",
                  })}
                  placeholder="Select Monthly Income"
                >
                  {[
                    "$0 - $4,999",
                    "$5,000 - $7,499",
                    "$2500 - $3,999",
                    "$4,000 - $4,999",
                    "Over $5,000",
                  ].map((item, key) => (
                    <option value={item} key={key}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.monthlyIncome && errors.monthlyIncome.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Employment Status</FormLabel>
                <Select
                  {...register("employmentStatus", {
                    required: "This is required",
                  })}
                  placeholder="Select Employment Status"
                >
                  {["Self Employed", "Employed", "Unemployed"].map(
                    (item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ),
                  )}
                </Select>
                <FormHelperText color="red">
                  {errors.employmentStatus && errors.employmentStatus.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>credit</FormLabel>
                <Select
                  {...register("credit", {
                    required: "This is required",
                  })}
                  placeholder="Select credit "
                >
                  {["Good", "Bad  ", "Average ", "Poor ", "Excellent"].map(
                    (item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ),
                  )}
                </Select>
                <FormHelperText color="red">
                  {errors.credit && errors.credit.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>State</FormLabel>
                <Select
                  {...register("state", {
                    required: "This is required",
                  })}
                  placeholder="Select state"
                >
                  {states.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.state && errors.state.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>City</FormLabel>
                <Input
                  id="City"
                  placeholder="city"
                  {...register("city", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.city && errors.city.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  id="zip"
                  placeholder="zip"
                  {...register("zip", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.zip && errors.zip.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  id="phoneNumber"
                  placeholder="phoneNumber"
                  {...register("phoneNumber", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  id="email"
                  placeholder="email"
                  {...register("email", {
                    required: "required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "invalid email address",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.email && errors.email.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  id="address"
                  placeholder="address"
                  {...register("address", {
                    required: "required",
                  })}
                />
                <FormHelperText color="red">
                  {errors.address && errors.address.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Sub ID</FormLabel>
                <Input id="subId" placeholder="subId" {...register("subId")} />
                <FormHelperText color="red">
                  {errors.subId && errors.subId.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>D ID</FormLabel>
                <Select
                  {...register("dId", {
                    required: "This is required",
                  })}
                  placeholder="Select Owner"
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => (
                    <option key={key} value={item}>
                      D-{item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.dId && errors.dId.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Date of birth</FormLabel>
                <Input
                  id="dob"
                  placeholder="dob"
                  type="date"
                  {...register("dob", {
                    required: "required",
                  })}
                />
                <FormHelperText color="red">
                  {errors.dob && errors.dob.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>SSN</FormLabel>
                <Input
                  id="ssn"
                  placeholder="ssn"
                  {...register("ssn", {
                    required: "required",
                  })}
                />
                <FormHelperText color="red">
                  {errors.ssn && errors.ssn.message}
                </FormHelperText>
              </FormControl>
              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid"
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddSettellment;
