import create from "zustand";
import { Navlinks } from "../Links";
import { Publiclinks } from "../publiclinks";
// import useAuthStore from "./AuthStore";
// note the "<MyStore>" next to create
export const useAppStore = create((set, get) => ({
  isSidebarOpen: false,
  sidebarLinks: Publiclinks,
  onSidebarOpen: () => set((state) => ({ isSidebarOpen: true })),
  onSidebarClose: () => {
    let list = Navlinks;
    list.map((x) => {
      if (x.open) {
        x.open = false;
      }
      return x;
    });
    set({ isSidebarOpen: false });
  },
  setNavlinks: (user) => {
    if (user) {
      set({ sidebarLinks: Navlinks });
    }
  },
  onOpenSubMenu: (props) => {
    let list = get().sidebarLinks;
    let l = list.find((x) => x.id === props);
    if (l.open) {
      l.open = false;
    } else {
      l.open = true;
    }
    set({ sidebarLinks: [...list] });
  },
}));
