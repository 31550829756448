import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Spinner,
} from "@chakra-ui/react";

function DisplayModal({ isOpen, onClose, Heading, content, action, loading }) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Heading}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{content}</ModalBody>
          <ModalFooter>
            {action && (
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            )}
            {action && (
              <Button onClick={action} variant="ghost">
                {loading ? <Spinner /> : "Ping"}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DisplayModal;
