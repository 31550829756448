import { Box, Spinner } from "@chakra-ui/react";
const RandomLoader = () => {
  return (
    <>
      <Box
        top={"0"}
        left={"-0"}
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        position={"absolute"}
        bg="rgba(0, 0, 0, 0.5)"
        w="100%"
        zIndex={99999}
        height={"100vh"}>
        <Spinner size={"xl"} />
      </Box>
    </>
  );
};

export default RandomLoader;
