import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Image,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import { RiSignalTowerFill } from "react-icons/ri";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { BiSupport } from "react-icons/bi";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  getWindowsLeads,
  getProjects,
  windowPingPost,
} from "../../services/apis/touAgencyApi";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import ErrorImage from "../../asserts/error.png";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import DisplayModal from "../../components/modals/displayModal";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
//d7d7d770
const WindowLeads = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1,
  );
  const [limit, setLimit] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("l")) : 10,
  );
  const [search, setSearch] = useState(
    searchParams.get("input") ? searchParams.get("input") : "",
  );
  const [projectId, setProjectId] = useState("");
  const [windowData, setWindowData] = useState(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isLoading: pingLoading } = useMutation(windowPingPost, {
    onError: (error) => {
      // toast.error(error.response.data.msg || "some thing went wrong");
      queryClient.invalidateQueries("windows_leads");
      onClose();
    },
    onSuccess: (res) => {
      // toast.success("Added successfully");
      queryClient.invalidateQueries("windows_leads");
      onClose();
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["windows_leads", page, limit],
    queryFn: () => getWindowsLeads(page, limit),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const {
    data: projectData,
    isLoading: projectIsLoading,
    isError: projectIsError,
  } = useQuery({
    queryKey: ["projects", page, limit, search],
    queryFn: () => getProjects(1, 50, search),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const pingPost = () => {
    let data = {
      id: windowData?.id,
      pId: projectId,
    };
    if (projectId) {
      mutate(data);
    }
  };

  if (isLoading || projectIsLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError || projectIsError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }
  const handelForm = (e) => {
    e.preventDefault();
    setSearch(e.target.input.value);
    setSearchParams({ input: e.target.input.value, p: page, l: limit });
  };

  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Window leads
        </Text>
      </Flex>
      {/* <Flex justify={"end"} p={4}>
        <form onSubmit={handelForm}>
          <Flex gap={2} align="center">
            <FormControl mt={4}>
              <Select
                onChange={(e) =>
                  setProjectId(e.target.value === "all" ? "" : e.target.value)
                }
                placeholder="Select project">
                <option value={"all"}>All</option>
                {projectData?.data?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </Select>
            </FormControl>
          </Flex>
        </form>
      </Flex> */}
      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Coverage">
                  C
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Accepted">
                  A
                </Tooltip>
              </Th>
              {/* <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Support">
                  S
                </Tooltip>
              </Th> */}
              <Th p="10px" textAlign="center">
                id
              </Th>
              <Th p="10px" textAlign="center">
                Zipcode
              </Th>
              <Th p="10px" textAlign="center">
                Ping
              </Th>
              <Th p="10px" textAlign="center">
                Name
              </Th>
              <Th p="10px" textAlign="center">
                Email
              </Th>
              <Th p="10px" textAlign="center">
                Phone
              </Th>
              <Th p="10px" textAlign="center">
                State
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Home Type
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Home Owner
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Call Back Time
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Number Of Windows
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Time Frame
              </Th>{" "}
              <Th p="10px" textAlign="center">
                Project Type
              </Th>
              <Th p="10px" textAlign="center">
                address
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data.data &&
              data.data.map((item, index) => {
                return (
                  <Tr
                    cursor={"pointer"}
                    onClick={() => navigate(`/window-leads/${item.id}`)}
                    key={index}
                  >
                    <Td textAlign="center" p="10px">
                      {item.post === null ? (
                        <Center>
                          <RiSignalTowerFill color="yellow" />
                        </Center>
                      ) : (
                        <>
                          {item.post?.coverage === "accepted" ? (
                            <Center>
                              <RiSignalTowerFill color="green" />
                            </Center>
                          ) : (
                            <Center>
                              <HiOutlineStatusOffline color="red" />
                            </Center>
                          )}
                        </>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.post === null ? (
                        <Center>
                          <AiOutlineCheckCircle color="yellow" />
                        </Center>
                      ) : (
                        <>
                          {item.post?.acceptedBid === "accepted" ? (
                            <Center>
                              <AiOutlineCheckCircle color="green" />
                            </Center>
                          ) : (
                            <Center>
                              <AiOutlineCloseCircle color="red" />
                            </Center>
                          )}
                        </>
                      )}
                    </Td>
                    {/* <Td textAlign="center" p="10px">
                      {item.supported ? (
                        <Center>
                          <BiSupport color="green" />
                        </Center>
                      ) : (
                        <Center>
                          <AiOutlineCloseCircle color="red" />
                        </Center>
                      )}
                    </Td> */}
                    <Td textAlign="" p="10px">
                      {item?.id}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.zip}
                    </Td>
                    <Td textAlign="" p="10px">
                      <Button
                        disabled={item.post !== null}
                        onClick={(e) => {
                          e.stopPropagation();
                          onOpen();
                          setWindowData(item);
                        }}
                      >
                        Ping
                      </Button>
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.firstname} {item?.lastname}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.email}
                    </Td>
                    <Td textAlign="" p="10px">
                      {item?.phoneNumber}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.state}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.homeType}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.homeOwener}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.callBtime}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.numberOfWindow}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.timeFrame}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.proType}
                    </Td>{" "}
                    <Td textAlign="" p="10px">
                      {item?.address}
                    </Td>{" "}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex p={[4, 4, 4, 4]} justify="space-between">
        {data ? (
          <Pagination
            count={data.count}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <PaginationLoader />
        )}
      </Flex>
      <DisplayModal
        isOpen={isOpen}
        onClose={onClose}
        Heading={"Window Ping"}
        loading={pingLoading}
        content={
          <Box>
            <Box>
              <Box>
                <Text fontWeight={600}>Name</Text>
                <Text>
                  {windowData?.firstname} {windowData?.lastname}
                </Text>
              </Box>
              <Box>
                <Text fontWeight={600}>Zipcode</Text>
                <Text>{windowData?.zip}</Text>
              </Box>
            </Box>
            <Box>
              <FormControl mt={4}>
                <Select
                  onChange={(e) =>
                    setProjectId(e.target.value === "all" ? "" : e.target.value)
                  }
                  placeholder="Select project"
                >
                  <option value={"all"}>All</option>
                  {projectData?.data?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        }
        action={pingPost}
      />
    </Box>
  );
};

export default WindowLeads;
